// ------------------------------------------
// UNITS
//-------------------------------------------
//Grid and List
.rc-list-unit,
.rc-grid-unit,
.rc-screener-unit,
.rc-row-unit {
  .rc-sur-title {
    color: var(--info);
  }
  .rc-stack-effect {
    &::before,
    &::after {
      //border-top: var(--rc-border-bg-dark);
      border-top: var(--rc-border-base);
      border-radius: 2px;
      border-top-width: 2px;
    }
  }
  .rc-thumbnail-wrapper {
    background-color: rgba(0, 0, 0, 0.55);
  }
  .rc-duration {
    height: toRem(24px);
  }
  .rc-infinity-symbol {
    font-size: $h2-font-size;
    line-height: 0.7;
  }
  a.stretched-link {
    text-decoration: none;
    &:hover {
      text-decoration: none !important;
    }
  }

  //Skeleton
  &--skeleton {
    .rc-thumbnail-wrapper {
      background-color: transparent;
    }
    .rc-grid-text {
      .rc-sur-title {
        .bg-primary {
          background-color: $info !important;
        }
      }
    }
  }
}
// ------------------------------------------
// Specific to Screener Unit
//-------------------------------------------
.rc-screener-unit {
  .h4 {
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
  }
  .rc-duration {
    padding: 0;
    color: $text-muted;
    background-color: transparent;
  }
  .rc-duration-start {
    display: none;
  }
}

// ------------------------------------------
// Specific to Grid Unit
//-------------------------------------------
.rc-grid-unit {
  margin-bottom: 3.25rem;
  .h4 {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }
}
@include media-breakpoint-up(xxl) {
  .rc-grid-unit {
    .h4 {
      font-size: $font-size-lg;
    }
  }
}
// ------------------------------------------
// Specific to List Unit
//-------------------------------------------
.rc-list-unit {
  header {
    max-width: toRem(800px); //à généraliser
    h3 {
      margin-bottom: 0.25rem;
      font-size: $h5-font-size;
      font-weight: $font-weight-bold;
    }
  }
  .rc-summary {
    margin-top: 0.5rem;
    max-width: toRem(800px); //à généraliser
    font-size: $font-size-base;
    color: var(--white);
  }
  .rc-col-thumbnail {
    max-width: toRem(350px);
  }
}

// ------------------------------------------
// Specific to Row Unit (look like table) (used for list import and list playlist Webtelly)
//-------------------------------------------
.rc-row-unit {
  .rc-col-thumbnail {
    max-width: toRem(200px);
  }

  h3,
  .h3 {
    margin-bottom: 0.2rem;
    font-size: $h5-font-size;
    line-height: $headings-line-height;
    font-weight: $font-weight-base;
  }
  .h3 {
    font-size: $font-size-base;
  }

  .rc-item-id {
    word-wrap: break-word;
  }
  .rc-col-status {
    text-align: right;

    .btn {
      font-size: toRem(15px);
      min-width: 7rem;
    }
    .badge {
      display: inline-block;
      margin-top: -5px;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      line-height: 1.25;
      min-width: 7rem;
      min-height: 1.75rem;
    }
  }
  .rc-col-dd-actions {
    text-align: center;
    padding-right: 0;
    .rc-dd-actions {
      .btn {
        padding: 0;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .rc-row-unit {
    .h3 {
      font-size: $h5-font-size;
    }

    .rc-col-status {
      text-align: right;

      .btn {
        font-size: toRem(15px);
        min-width: 7rem;
      }
      .badge {
        font-size: toRem(15px);
        font-weight: $font-weight-bold;
      }
    }
    .rc-col-dd-actions {
      padding-right: 5px;
    }
  }
}

// ------------------------------------------
// Expired Unit
//-------------------------------------------
.rc-expired-unit {
  .rc-thumbnail-wrapper img {
    filter: brightness(50%);
  }

  &:hover .rc-thumbnail-wrapper img {
    filter: brightness(60%);
  }
}

// ------------------------------------------
// BATCH SELECTION (grid view and list view)
//-------------------------------------------/
.rc-grid-view,
.rc-list-view {
  input[type="checkbox"] {
    &:checked + label {
      border: 3px solid var(--active);
    }
  }
}
