// ------------------------------------------
// SCHEDULABLE : (stream detail, playlist detail)
//-------------------------------------------
.rc-l-schedulable {
  .rc-container-list-options {
    .rc-titles-count {
      text-transform: unset;
      font-weight: $font-weight-normal !important;
    }
  }
  .rc-list-nav {
    max-height: toRem(550px);
    overflow-y: auto;
    padding-right: 1rem;
  }
  .rc-list-events {
    li .btn {
      max-width: 100%;
      width: 100%;
      padding: 1.25rem;
      margin-bottom: 1rem;
      text-align: left;
      font-weight: $font-weight-normal;
      background-color: var(--blue-matt);
      border-color: var(--blue-matt);
      &:hover,
      &:focus {
        background-color: var(--dark);
        border-color: var(--dark);
      }
    }
    .h5 {
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
      font-weight: $font-weight-bold;
    }
    &.rc-list-scroll {
      margin-right: 2px;
      max-height: toRem(450px);
      overflow-y: auto;
      padding: 4px 1rem 4px 4px;
    }
  }
}

@media (min-height: 600px) and (min-width: 1200px) {
  .rc-l-schedulable {
    .rc-list-nav {
      max-height: toRem(650px);
      overflow-y: auto;
      padding-right: 1rem;
    }
    .rc-list-events {
      &.rc-list-scroll {
        max-height: toRem(650px);
      }
    }
  }
}
// ------------------------------------------
// List options : edit, reorder, autoplay, etc
//-------------------------------------------
.rc-col-options {
  flex-grow: 1;

  ul.rc-list-options {
    display: flex;
    justify-content: stretch;
    align-items: center;
    margin-bottom: 0;
    width: 100%;

    li {
      margin-right: 0.5rem;

      &:nth-child(2),
      &:last-of-type {
        margin-right: 0;
      }
    }
    .rc-btn-icon-text {
      width: 100%;
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      svg,
      span {
        display: block !important;
      }
      svg {
        font-size: 150% !important;
      }
      &.rc-btn-share {
        margin-left: -0.6rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .rc-col-options {
    margin-left: 0.5rem;
  }
}
// ------------------------------------------
// Listing with editing mode
//-------------------------------------------
.rc-listing {
  .rc-sur-title {
    color: var(--info);
    margin-bottom: 0.1rem;
  }
  article.rc-item-playing {
    padding-bottom: 2rem;
    .rc-sur-title {
      margin-bottom: 0.25rem;
    }
  }
  &--editing {
    .btn-outline-light {
      font-size: 0.875rem;
      padding-right: 0.3rem;
      padding-left: 0.3rem;
    }

    .rc-list-view {
      > ul > li {
        margin-bottom: 0.5rem;
        padding-bottom: 0.7rem;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .rc-list-unit {
      .rc-ribbon {
        display: none;
      }
      .rc-summary {
        display: none;
      }
      .rc-with-cc {
        display: none;
      }
      //to avoid border on last-child when no CC (to be remove after refacto)
      .rc-list-border li:nth-child(3) {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
      }
    }

    .rc-col-thumbnail {
      display: none;
    }

    #rc-list {
      .rc-col-thumbnail {
        display: block;
      }
      .rc-list-view {
        > ul > li {
          border-bottom: 0px;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .rc-listing {
    &--editing {
      .btn-outline-light {
        font-size: $font-size-base;
        padding-right: 1rem;
        padding-left: 0.7rem;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .rc-listing {
    &--editing {
      .rc-col-thumbnail {
        display: block;
      }

      .rc-list-view {
        > ul > li {
          margin-bottom: 0.7rem;
        }
      }
      #rc-list .rc-list-view {
        > ul > li {
          border-bottom: var(--rc-border-base);
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .rc-listing {
    &--editing {
      .rc-col-thumbnail {
        //@extend .col-lg-2;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
      }
      #rc-edit {
        .rc-col-thumbnail {
          flex: 0 0 17.5%;
          max-width: 17.5%;
        }
      }

      #rc-reorder {
        .rc-col-thumbnail {
          flex: 0 0 18.5%;
          max-width: 18.5%;
        }
      }
    }
  }
}

// ------------------------------------------
// Empty
//-------------------------------------------
.rc-empty {
  padding-top: 2rem;
  padding-bottom: 3rem;
  h2 {
    margin-bottom: 1rem;
  }
  ul li {
    a {
      text-decoration: underline;
    }
  }
}
