// ------------------------------------------
// LAYOUT FOR ADVANCED LIST
//-------------------------------------------
.rc-advanced-list {
  &--header {
    @extend .rc-content-header;

    .rc-container-title {
      padding-bottom: 0.75rem;
      justify-content: space-between;
      border-bottom: var(--rc-border-bg-dark);
      border-bottom-width: 2px;
      min-height: 60.7px;
    }

    .rc-container-search-filters {
      padding: 1rem 0;
      .rc-search-wrapper {
        .rc-form-search {
          margin-bottom: 0;
          margin-top: -2px;

          input {
            height: toRem(52px) !important;
            border-color: var(--darker);
          }
        }
      }

      .rc-dropdown-filters {
        .text-muted {
          color: $gray !important;
        }
      }

      .rc-btn-icon.btn-info {
        max-width: unset;
      }
    }
  }
  &--body {
    @extend .rc-content-body;

    .rc-container-list-options {
      margin-top: 0;
      padding-bottom: 1rem;
      .rc-titles-count {
        text-transform: unset;
        font-weight: $font-weight-base !important;
      }
    }

    .rc-no-results {
      padding-top: 2rem;
      padding-bottom: 7rem;
      h2 {
        margin-bottom: 1rem;
      }

      ul.rc-list-bullet li {
        font-size: $font-size-lg;
      }
    }
    .rc-list-unit {
      .custom-checkbox {
        margin-left: 2.5rem;
      }
      input[type="checkbox"] {
        + label {
          border-left: 4px solid transparent;
          padding-left: 0.5rem;

          &::after,
          &::before {
            left: -2.5rem;
          }
        }
        &:checked + label {
          border-left-color: var(--active);
        }
      }
    }
  }

  .rc-btn-load-more .progress-bar.bg-primary {
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.15) 50%,
      rgba(0, 0, 0, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
}

@include media-breakpoint-up(md) {
  .rc-advanced-list {
    &--header {
      .rc-container-title {
        min-height: 70.4px;
      }
    }
  }
}
