// ------------------------------------------
// CAROUSEL
//-------------------------------------------
.rc-carousel {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }

  &--header {
    a {
      text-decoration: underline;
    }
  }
}
