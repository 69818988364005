// ------------------------------------------
// GRID BASE (header, main, footer)
//-------------------------------------------
html,
body {
  height: 100vh;
}

.rc-wrapper {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  background: $blue-black;
  background-image: url(./assets/img/bg_left.svg),
    url(./assets/img/bg_right.svg), linear-gradient(#1d414e 0%, #000f14 1080px);
  background-position: 0 toRem(180px), 100% 0, 0 0;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 26%, 47%, cover;
}

.container {
  max-width: toRem(2000px);
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

//Used for Monitor and Schedule
.container-fluid {
  padding-right: 1.25rem;
  padding-left: 1.25rem;

  > div,
  > article {
    border-radius: var(--rc-border-radius-wrapper);
  }
}
@include media-breakpoint-down(md) {
  .container-fluid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@include media-breakpoint-up(xxl) {
  .container-fluid,
  .container {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@include media-breakpoint-up(xxxl) {
  .container-fluid,
  .container {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}

// ------------------------------------------
// Template base for content
//-------------------------------------------
.rc-content-header {
  padding: 1.5rem 1rem 1rem;
  border-top-left-radius: var(--rc-border-radius-wrapper);
  border-top-right-radius: var(--rc-border-radius-wrapper);
  border-bottom: var(--rc-border-separator);
  background-color: var(--dark);

  &.border-bottom-0 {
    border-radius: var(--rc-border-radius-wrapper);
  }

  .rc-container-toolbar {
    border-top: var(--rc-border-bg-dark);
    border-top-width: 2px;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}
.rc-content-body {
  border-bottom-left-radius: var(--rc-border-radius-wrapper);
  border-bottom-right-radius: var(--rc-border-radius-wrapper);
  background-color: var(--blue);

  .rc-col-main {
    padding: 1rem;

    &--with-tabs {
      .nav-tabs {
        margin-top: 0.5rem;
        margin-bottom: toRem(15px);
        a.nav-link {
          padding-top: 0.6rem;
          padding-bottom: 0.85rem;
          line-height: 1.1;
        }
      }
    }
  }
  .rc-col-aside {
    padding: 1rem;
    border-top: var(--rc-border-separator);

    h2 {
      border-bottom: var(--rc-border-base);
      border-width: 2px;
      padding-top: 0.5rem;
      padding-bottom: 0.7rem;
      margin-bottom: 1rem;
    }
    .rc-container-list-options h2 {
      border-bottom: 0;
      padding-top: unset;
      padding-bottom: unset;
    }
  }
}

@include media-breakpoint-up(lg) {
  .rc-content-header {
    padding: 2rem 1.5rem 1rem;
  }
  .rc-content-body {
    .rc-col-main,
    .rc-col-aside {
      padding: 1.5rem;
    }
  }
}
@include media-breakpoint-up(xl) {
  .rc-content-header {
    padding: 2.5rem 2.5rem 1rem;
  }
  .rc-content-body {
    .rc-col-main,
    .rc-col-aside {
      padding: 2.5rem;
    }
    .rc-col-aside {
      border-top: 0;
      border-left: var(--rc-border-separator);
    }
  }
}

// ------------------------------------------
// Content with Scroll
//-------------------------------------------
.rc-content-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: toRem(300px);

  &.h-100 {
    max-height: unset;
  }
}

@include media-breakpoint-up(xl) {
  .rc-col-content {
    height: 100%;
  }
}

@media (max-height: 599px) and (min-width: 1200px) {
  .rc-content-scroll {
    max-height: toRem(350px);
  }
}

@media (min-height: 600px) and (min-width: 1200px) {
  //Content fit viewport
  .rc-grid-fit-viewport {
    main {
      max-height: calc(100vh - var(--rc-height-headerFooter));
    }
    .rc-row-fit-viewport {
      height: 100%;
      overflow: hidden;
    }
    .rc-col-content {
      height: calc(100vh - var(--rc-height-headerFooter));
      display: flex;
      flex-direction: column;

      .rc-content-scroll {
        max-height: unset;
        flex-grow: 0;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }

    footer.rc-footer {
      margin-top: 0;
    }
  }
}

@media (min-height: 900px) and (min-width: 1200px) {
  .rc-main-navigation {
    margin-bottom: 1.25rem;
  }
  //Content fit viewport
  .rc-grid-fit-viewport {
    .rc-col-content {
      height: calc(100vh - var(--rc-height-headerFooter) - 1.25rem);
    }
  }
}

// ------------------------------------------
// Logos team (header sidebar and home user)
//-------------------------------------------
.rc-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: toRem(150px);
  min-width: toRem(260px);
  text-align: center;
  font-size: $h2-font-size;
  margin: 0 1rem 1rem;

  .rc-logo-team {
    max-width: toRem(260px);
    max-height: toRem(120px);
  }
}
@include media-breakpoint-up(xl) {
  .rc-logo-wrapper {
    margin: 0rem 1rem 2rem 1rem;

    .rc-logo-team {
      max-width: toRem(260px);
      max-height: toRem(150px);
    }
  }
}
// ------------------------------------------
// Utilities
//-------------------------------------------
.mw-300 {
  max-width: toRem(300px);
  width: 100%;
}
.mw-350 {
  max-width: toRem(350px);
  width: 100%;
}
.mw-400 {
  max-width: toRem(400px);
  width: 100%;
}
.rc-container-overflow {
  @include overflow-gradient(toRem(220px));

  &--border {
    border-bottom: var(--rc-border-bg-light);
    border-bottom-width: 2px;
    border-radius: 4px;
  }
}
.rc-overflow-hidden {
  overflow: hidden;
}
// ------------------------------------------
// Vidéo
//-------------------------------------------

.rc-video {
  > div:first-of-type {
    video {
      max-width: 100% !important;
    }
    .video-js {
      z-index: 2;
      width: 100% !important;
      height: 100% !important;
    }

    .vjs-big-play-button {
      left: calc(50% - 2.7rem);
      top: calc(50% - 1rem);
    }
  }
}

// ------------------------------------------
// Scrollbar
//-------------------------------------------
/* width */
::-webkit-scrollbar {
  width: 10px;
  background: var(--darker);
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blue-light);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blue-light);
}
.bg-light,
.modal,
.dropdown-menu {
  ::-webkit-scrollbar {
    background: $gray-300;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $gray-600;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $gray-700;
  }
}
