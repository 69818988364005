// ------------------------------------------
// FORM
//-------------------------------------------
// ------------------------------------------
// LABEL
//-------------------------------------------
.col-form-label,
label {
  font-weight: $font-weight-bold;
}
.custom-control-label {
  font-weight: $font-weight-base;
}
// ------------------------------------------
// DROPDOWN
//-------------------------------------------
.dropdown,
.rc-btn-toggle {
  .rc-chevron-up-bold,
  .rc-chevron-down-bold {
    margin: 0.1rem -0.2rem 0 0.5rem;
  }
  .rc-chevron-up-bold {
    display: none;
  }
  .rc-chevron-down-bold {
    display: inline-block;
  }

  &.show,
  &[aria-expanded="true"] {
    .rc-chevron-up-bold {
      display: inline-block;
    }
    .rc-chevron-down-bold {
      display: none;
    }
  }
}

.dropdown-menu {
  color: var(--black);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3) !important;

  button.dropdown-item:focus {
    outline: 2.5px solid $focus-color !important;
    outline-offset: 1px;
  }
  .dropdown-item {
    &.active,
    &:active {
      color: var(--black) !important;
      //background-color: $gray-200 !important;
    }
  }
  .rc-link-icon--text {
    font-weight: $font-weight-bold;
    svg {
      margin-right: 0.5rem;
    }
    + svg {
      float: right;
      margin-right: 0;
    }
  }
  a:hover {
    text-decoration: none !important;
  }
}

// ------------------------------------------
// SELECT
//-------------------------------------------
.rc-select-dark {
  background: var(--blue-light)
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23FFFFFF' d='M.67,5.43,7,11.78a1.4,1.4,0,0,0,2,0l6.35-6.35a1.39,1.39,0,0,0-2-2L8,8.84,2.63,3.47a1.39,1.39,0,0,0-2,2Z'/%3e%3c/svg%3e")
    no-repeat right 0.5rem center/14px 12px;
  color: var(--white);
  padding-right: 2rem;
  @extend .rc-ellipsis-simple;
}

// ------------------------------------------
// CHECKBOX GROUP
//-------------------------------------------
//Checkbox Group
.rc-checkbox-group .custom-checkbox {
  padding: 0.5rem 1.25rem 0.5rem 3.25rem;
  border-bottom: var(--rc-border-bg-light);

  &:last-child {
    border: none;
  }
}

.rc-checkbox-group-overflow {
  @include overflow-gradient(toRem(220px));
  border-bottom: var(--rc-border-bg-light);
  border-bottom-width: 2px;
  border-radius: 4px;

  .custom-checkbox {
    padding: 0.5rem 1.25rem 0.5rem 3.25rem;
    border-bottom: var(--rc-border-bg-light);

    &:first-of-type {
      padding-top: 0.25rem;
    }

    &:last-child {
      border: none;
    }
  }
}

// ------------------------------------------
// INPUT TEXT WITH BUTTON (ex.: permalink with btn copy)
//-------------------------------------------
.rc-input-btn-wrapper {
  position: relative;
  max-width: 100%;

  input {
    width: 100%;
    padding-right: 5rem;

    &::placeholder {
      color: $text-muted;
    }
  }
  .btn {
    position: absolute;
    top: toRem(5px);
    right: toRem(5px);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.25rem;
  }
  .form-control-lg + .btn {
    top: 0.5rem;
    right: 0.5rem;
  }
}
// ------------------------------------------
// SEARCH
//-------------------------------------------

@include media-breakpoint-up(md) {
  .rc-search-wrapper {
    width: toRem(300px);
  }
}
// ------------------------------------------
// DATE PICKER
//-------------------------------------------
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.b-form-datepicker {
  > label {
    line-height: 1.5;
    padding-right: 0.25rem !important;
  }
  > .btn {
    padding: 7px;
  }
  &.is-invalid {
    padding-right: 0 !important;
  }
}
.b-calendar {
  .b-calendar-nav {
    button {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .b-calendar-grid {
    &:focus {
      box-shadow: none !important;
    }
    &.form-control {
      border-color: transparent;
      border-radius: 0;
    }
    .b-calendar-grid-body {
      .col[data-date] {
        .btn {
          min-height: unset !important;
          min-width: unset !important;
          margin: 5px auto !important;
          box-shadow: none !important;
          &:focus,
          &.focus,
          &:hover {
            background-color: $dark !important;
            color: $white !important;
          }
        }
        &[aria-selected="true"] {
          .btn {
            background-color: $primary;
            color: $white !important;
          }
        }
      }
      .btn-outline-primary {
        background-color: $active;
        color: black;
      }
      .btn.disabled,
      .btn:disabled {
        opacity: 0.4;
      }
    }
    footer {
      margin-top: 0.5rem;
      padding: 0.5rem 0.25rem 0.25rem;
      background: transparent !important;
    }
  }
  .b-calendar-footer {
    button {
      font-weight: $font-weight-normal;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  .text-muted {
    color: $gray-600 !important;
  }
}
// ------------------------------------------
// TIME PICKER
//-------------------------------------------
.b-time-footer .b-form-date-controls {
  flex-wrap: nowrap !important;
  padding-top: 0.5rem;
  button {
    font-weight: $font-weight-normal;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
// ------------------------------------------
// SWITCH (on, off)
//-------------------------------------------
.custom-switch .custom-control-label {
  &::before {
    top: 2px;
    height: toRem(20px);
  }
  &::after {
    top: 0.375rem;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(0.9rem);
}

// ------------------------------------------
// File upload
//-------------------------------------------
.custom-file-input {
  ~ .custom-file-label {
    margin-top: 0;
    font-weight: 400;
  }
}
.b-custom-control-md {
  .custom-file-input {
    ~ .custom-file-label {
      padding: $input-padding-y $input-padding-x;
    }
  }
}
.rc-progress-upload {
  line-height: 1.35;
  height: toRem(38px);
  display: block;

  .progress-bar {
    display: block;
  }
  .rc-progress-label {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0.5rem;
    font-size: $font-size-sm;

    &--name {
      text-align: left;
      @extend .rc-ellipsis-simple;
    }
    &--percent {
      flex-shrink: 0;
      padding-left: 0.25rem;
    }
  }
}
// ------------------------------------------
// Radio button for colors
//-------------------------------------------

.custom-radio {
  &.rc-radio-color {
    --rc-radio-color: #{$dark};
    min-height: 1.5rem;
    label {
      &::before {
        background-color: color-mix(
          in srgb,
          var(--rc-radio-color),
          #fff 15%
        ) !important;
        border-color: color-mix(
          in srgb,
          var(--rc-radio-color),
          #fff 15%
        ) !important;
        width: 1.5rem;
        height: 1.5rem;
      }
      &::after {
        width: 1.5rem;
        height: 1.5rem;
      }
      .rc-text-color {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
      &:hover {
        .rc-text-color {
          background-color: var(--lighter);
          padding: 0.25rem 0.5rem;
          font-size: $font-size-sm;
          width: auto;
          height: auto;
          margin: unset;
          overflow: unset;
          clip: unset;
          top: -1.7rem;
          left: -2.2rem;
          border-radius: 4px;
        }
      }
    }
  }
}

// ------------------------------------------
// COMMON
//-------------------------------------------
// Same focus everywhere
.modal-header .close:focus,
.btn:focus,
.btn.focus,
.custom-select:focus,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.form-control:focus,
.b-form-tags:focus,
.b-form-tags.focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: $input-btn-focus-box-shadow !important;

  .bg-light & {
    box-shadow: 0 0 0 0.125rem $light, 0 0 0 0.25rem $focus-color !important;
  }
  .bg-info & {
    box-shadow: 0 0 0 0.125rem $info, 0 0 0 0.25rem $black !important;
  }
  .rc-modal-form & {
    box-shadow: 0 0 0 0.125rem $white, 0 0 0 0.25rem $focus-color !important;
  }
}

// Remove green label on valid custom-controls (radio and checkbox)
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: inherit !important;
}

// Remove green input on valid custom-controls (radio and checkbox)
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: var(--blue) !important;
  background-color: var(--blue) !important;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: var(--blue) !important;
}
