// ------------------------------------------
// BUTTONS
//-------------------------------------------
.btn {
  min-height: toRem(38px);
  max-width: toRem(300px);
}
.btn-sm {
  min-height: toRem(28px);
  min-width: toRem(30px);
}
@include media-breakpoint-down(md) {
  .btn-lg {
    font-size: $font-size-lg;
  }
}
//Button Login (Email/Google/Microsoft)
.rc-btn-login {
  display: flex;
  align-items: center;
  text-align: left;
  color: $gray-900;
  background-color: white;
  border: 2px solid $secondary;
  margin-bottom: 0.625rem;
  font-size: $font-size-base;
  font-weight: inherit;

  img {
    margin-right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
  }
  svg {
    margin-right: 1rem;
  }
  &:hover,
  &:focus {
    background-color: $lighter;
    color: black;
  }
}

// ------------------------------------------
// Dark
//-------------------------------------------
.btn-dark:active,
.btn-dark.active,
.btn-dark.selected {
  border-color: var(--yellow) !important;
}
.btn-dark:not(:disabled) {
  &:hover {
    background-color: #031c26;
    border-color: #021922;
  }
}

.navbar-dark .navbar-nav .btn-primary.nav-link {
  color: var(--black);
}

// ------------------------------------------
// Button Link - secondary (modale add playlist)
//-------------------------------------------
.btn-link {
  text-decoration: underline;

  .bg-light & {
    color: $black;
  }
}
.rc-btn-link-secondary {
  color: $secondary;

  &:hover,
  &:focus {
    color: $black;
    background-color: $light;
  }
}

// ------------------------------------------
// Group Radio (queue/history, RcAppliedFilters)
//-------------------------------------------
.rc-group-toggle {
  margin-bottom: 0;
  padding: 2px;
  border: var(--rc-border-bg-dark);
  border-width: 2px;
  border-radius: toRem(5px);

  .btn-outline-info,
  .btn-outline-secondary {
    border: 1px solid transparent !important;
    color: rgba($white, 0.8);
    border-radius: toRem(2px);
  }

  .btn {
    border-radius: 2px !important;
    margin-right: 8px !important;
    min-height: toRem(30px) !important;
    min-width: toRem(36px);
    padding: 0;
    text-align: center;
    padding: 4px;
    //font-weight: $font-weight-normal;

    svg {
      vertical-align: middle;

      font-size: 125%;
    }

    &:last-of-type {
      margin-right: 0px !important;
    }
  }
}

// ------------------------------------------
// Icon
//-------------------------------------------
.rc-btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.1rem;
  padding-left: 0.1rem;
  min-width: toRem(41px);
  height: toRem(38px);
  text-decoration: none;
  &.btn-sm {
    min-width: toRem(30px);
    height: toRem(28px);
  }
  &.btn-lg {
    height: toRem(48px);
  }

  &--text,
  &--text-right,
  &--text-left {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  &--text-right {
    .rc-icon-svg {
      margin-right: 0.5rem;
    }
    &.btn-lg {
      padding-right: 1.25rem;
    }
  }
  &--text-left {
    .rc-icon-svg {
      margin-left: 0.5rem;
    }
    &.btn-lg {
      padding-left: 1.25rem;
    }
  }
}
// ------------------------------------------
// With badge
//-------------------------------------------
.rc-wrapper-btn-badge {
  position: relative;
  .badge {
    position: absolute;
    //height :30px;
    top: toRem(-15px);
    right: toRem(-15px);
  }
}
// ------------------------------------------
// Btn Occurrence
//-------------------------------------------
.rc-btn-occ {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-height: unset;
  padding: 0.375rem;
  text-align: left;
  font-weight: $font-weight-normal;
  border: 0;
  border-left: 8px solid color-mix(in srgb, var(--rc-color-event), #fff 10%);
  background-color: var(--rc-color-event);
  opacity: 1 !important;

  &:hover {
    background-color: color-mix(in srgb, var(--rc-color-event), #000 7%);
    border-left: 8px solid color-mix(in srgb, var(--rc-color-event), #fff 15%);
  }

  &.btn-dark {
    border-left-color: $dark;
  }

  &.rc-btn-with-alert {
    border-left-color: $danger;
  }

  .row {
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
  }

  //Icons
  .rc-icon-occ {
    position: relative;
    padding-right: 0.375rem;
    padding-top: 0.1rem;
  }
  .rc-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 4px;
    background-color: color-mix(in srgb, var(--rc-color-event), #000 20%);
  }

  .rc-branding {
    position: absolute;
    top: toRem(-3px);
    left: toRem(-6px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: toRem(16px);
    width: toRem(16px);
    padding: 0;
    color: color-mix(in srgb, var(--rc-color-event), #000 20%);
    border-radius: 50%;
    border: 2px solid color-mix(in srgb, var(--rc-color-event), #000 20%);
    font-size: toRem(11px);
    font-weight: $font-weight-bold;
    line-height: 0.9;
    vertical-align: unset;
    background-color: white;
  }
  .rc-icon-occ-danger,
  .rc-icon-occ-warning {
    text-align: center;
    padding-right: 0.375rem;
    padding-top: 0.325rem;
  }

  //Title
  .rc-occ-title {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    word-break: break-word;
    @include line-clamp(1, var(--white));
    white-space: unset;
    // @extend .rc-ellipsis-simple;
    // padding-right: 2rem;

    &--extra {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }
  }

  //Time
  .rc-occ-time {
    margin-top: 2.5px;
    font-size: 0.75rem;
    text-transform: lowercase;

    .alert-danger {
      margin-top: toRem(5px);
    }
  }

  //To DO
  //(only dans monitor)
  &.rc-btn-base {
    //border-left: 0;
    border-color: $blue-darker;
    // margin-left: 8px;
    // width: calc(100% - 8px);

    // &:active,
    // &.active {
    //   border-color: var(--active);
    //   border-left: 8px;
    //   margin-left: 0;
    //   width: calc(100% + 8px);
    // }
    .rc-icon-container {
      background-color: unset;
    }
    .rc-icon-occ {
      .rc-icon-svg {
        margin-left: -1px;
        margin-top: -10px;
      }
    }

    &.rc-xs-occ {
      padding-top: 2px;
      padding-bottom: 0;
      .rc-icon-occ {
        .rc-icon-svg {
          font-size: 80% !important;
          margin-top: -1px;
        }
      }
      .row {
        align-items: center;
      }
      .col {
        display: flex;
        align-items: baseline;

        .rc-occ-title {
          width: unset;
          padding-right: 0.8rem;
        }
      }
      &:active,
      &.active {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  //Active
  &:not(:disabled) {
    &:not(.disabled) {
      &:active,
      &.active {
        border: 2px solid var(--active) !important;
        border-left: 8px solid var(--active) !important;
        background-color: #031c26 !important;

        padding-top: calc(0.375rem - 2px);
        padding-bottom: calc(0.375rem - 2px);
      }
    }
  }

  //Size
  &.rc-xs-occ {
    .rc-occ-desc {
      display: none;
    }
    .rc-occ-time {
      font-size: 0.75rem;
    }
  }
  &.rc-sm-occ,
  &.rc-xs-occ {
    font-size: $font-size-base;
    .rc-occ-title {
      font-size: $font-size-base;

      &--extra {
        font-size: $font-size-sm;
      }
    }
    .rc-occ-time {
      margin-top: 0;
      //font-size: $font-size-sm;
    }
  }

  &.rc-lg-occ {
    .rc-occ-title {
      word-break: break-word;
      @include line-clamp(2, var(--white));
      white-space: unset;
      //padding-right: 0;
    }
  }

  //Menu contextuel
  + .rc-dropdown-context {
    z-index: 200;
    .dropdown-toggle {
      height: toRem(28px) !important;
      width: toRem(28px) !important;
      padding: 0;
      min-height: unset;
      min-width: unset;
      border-radius: 50%;
      background-color: $active !important;
      outline: 10px solid rgba($active, 0.3);

      + .dropdown-menu {
        margin-top: -15px;
      }
    }
  }
}
.rc-dropdown-context {
  visibility: hidden;
}

//1900px

@include media-breakpoint-up(xxxl) {
  .rc-btn-occ {
    .rc-occ-title {
      font-size: $font-size-lg;
    }
    .rc-occ-time {
      font-size: $font-size-sm;
    }

    &.rc-sm-occ,
    &.rc-xs-occ {
      .rc-occ-title {
        font-size: $font-size-base;
        //width: calc(100% - 2px);

        &--extra {
          font-size: $font-size-sm;
        }
      }
    }
  }
}

// ------------------------------------------
// Btn Show more/less
//-------------------------------------------
.btn-show-more,
.btn-show-less {
  min-height: auto;
  padding: 0;
  font-weight: $font-weight-bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: none;
  }
  svg {
    margin-left: toRem(3px);
  }
}

// ------------------------------------------
// Btn close toast - overrides Bootstrap
//-------------------------------------------
.toast .rc-btn-close {
  position: absolute;
  top: toRem(5px);
  right: toRem(5px);
}
