// ------------------------------------------
// LAYOUT FOR TEAMS and MEMBERSHIPS
//-------------------------------------------
.rc-l-teams {
  .rc-row-unit {
    .rc-wrapper-text-badge {
      margin-bottom: 0.125rem;
      .badge {
        margin-left: 0.325rem;
        padding: 0.25rem 0.5rem 0.3rem;
        font-size: $font-size-sm;
      }
    }

    .rc-col-btn-actions {
      max-width: 13rem;
    }
    .rc-tooltip-admins .tooltip-inner {
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      color: $text-muted;
    }
  }
}

@include media-breakpoint-down(sm) {
  .rc-l-teams {
    .rc-col-btn-actions {
      max-width: unset;
      padding-top: 1rem;
      .btn {
        max-width: 100%;
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
}
