// ------------------------------------------
// TYPOGRAPHY
//-------------------------------------------
$h1-font-size: $font-size-base * 2.75; //44px (font-weight :normal)
$h2-font-size: $font-size-base * 1.875; //30px (font-weight :500)
$h3-font-size: $font-size-base * 1.5; //24px (font-weight:normal)
$h4-font-size: $font-size-base * 1.3215; //21px
$h5-font-size: $font-size-base * 1.125; //18px
$h6-font-size: $font-size-base * 1.125; //18px

@include media-breakpoint-down(sm) {
  h1,
  .h1 {
    font-size: $h1-font-size * 0.8;
  }
  h2,
  .h2 {
    font-size: $h2-font-size * 0.8;
  }
  h3,
  .h3 {
    font-size: $h3-font-size * 0.8;
  }
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-size: $font-size-lg;
  }
}

//Font-weight bold for h2, h5, h6
h2,
.h2,
h5,
.h5,
h6,
.h6 {
  font-weight: $font-weight-bold !important;
}
//Max-width for title
h1,
.h1 {
  max-width: toRem(1000px);
}
h2,
.h2,
h3,
.h3,
h4,
.h5 {
  max-width: toRem(800px);
}
.rc-summary,
p {
  max-width: toRem(800px);

  &.rc-intro--xl {
    font-size: $font-size-lg;
  }
}
.bg-primary {
  color: var(--black);
}
.rc-sur-title {
  color: $primary;
}

// ------------------------------------------
// Links
//-------------------------------------------
a {
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 2.5px solid $focus-color !important;
    outline-offset: 2px;
    border-radius: 1px;
  }

  &.btn {
    &:not(.btn-link) {
      &:hover {
        text-decoration: none !important;
      }
    }

    &:focus {
      outline: 0 !important;
    }
  }
  &.disabled {
    cursor: default;

    &:hover,
    &:focus {
      color: var(--white);
      text-decoration: none !important;
    }
  }
}
a.rc-link-icon--text {
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.75rem;
  }
}
// ------------------------------------------
// Abbr
//-------------------------------------------

abbr {
  &.rc-parental-ratings {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    min-height: 1.25rem;
    padding-right: 2.5px;
    padding-left: 2.5px;
    font-size: toRem(12px);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 4px;
  }
}

// ------------------------------------------
// Tag 'Playing now'
//-------------------------------------------
.tag-now {
  height: toRem(30px);
  padding: 0.25rem 1rem;
  font-size: $font-size-base;
  line-height: 1.1;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: var(--active);
  border: 2px solid var(--active);
  border-radius: $border-radius;
  background-color: rgba(0, 0, 0, 0.6);
}

// ------------------------------------------
// Tooltips
//-------------------------------------------
.b-tooltip {
  .tooltip-inner {
    max-width: 100%;
    //padding: 0.2rem 1rem 0.3rem 0.8rem;
    padding: 0.325rem 1rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    font-weight: $font-weight-bold;
    ul {
      margin-bottom: 0;
    }
  }
  &.bs-tooltip-left {
    .tooltip-inner {
      padding: 0.2rem 0.8rem 0.3rem 1rem;
    }
  }
  .tooltip-inner {
    border-right: 0.1px solid $tooltip-bg;
    border-left: 0.1px solid $tooltip-bg;
  }
}

// ------------------------------------------
// Ribbon yellow
//-------------------------------------------
.rc-ribbon {
  position: absolute;
  top: toRem(18px);
  left: toRem(-10px);
  padding: toRem(4px) toRem(10px) toRem(6px);
  line-height: 1;
  font-size: toRem(18px);
  font-weight: 500;
  color: black;
  background: var(--yellow);
  z-index: 1;

  &::before {
    content: "";
    left: 0;
    position: absolute;
    top: 100%;
    border-style: solid;
    border-width: toRem(10px) 0 0 toRem(10px);
    border-color: #9b8300 transparent transparent transparent;
  }
}
@include media-breakpoint-up(lg) {
  .rc-ribbon {
    padding: toRem(8px) toRem(10px) toRem(10px);
    font-size: toRem(21px) !important;
  }
}
// ------------------------------------------
// List Data (My account and Detail page - metadata)
//-------------------------------------------
ul.rc-list-data {
  .list-group-item {
    padding: 1rem 0;
    border-bottom: var(--rc-border-base);
    background-color: transparent;

    &:last-child {
      border-bottom: 0;
    }

    h3,
    .h3 {
      margin-bottom: 0.5rem;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      line-height: $line-height-base;
    }
    @include media-breakpoint-up(sm) {
      h3,
      .h3 {
        margin-bottom: 0;
      }
    }

    ul {
      li {
        margin-bottom: 0.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.bg-dark {
  ul.rc-list-data {
    .list-group-item {
      border-bottom: var(--rc-border-bg-dark);
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &.list-group-item {
    border-bottom: var(--rc-border-bg-dark);
  }
}
.bg-blue {
  ul.rc-list-data {
    .list-group-item {
      //border-bottom: var(--rc-border-bg-blue);
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.rc-modal {
  ul.rc-list-data {
    .list-group-item {
      border-bottom: 1px solid #{$gray-300};
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

// ------------------------------------------
// Cookie
//-------------------------------------------
.rc-alert-cookie {
  a {
    color: var(--black);
    text-decoration: underline;
  }
}

// ------------------------------------------
// CC
//-------------------------------------------
.rc-cc {
  cursor: default;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  line-height: 1;
  width: 1.5rem;
  border-radius: toRem(3px);
  text-align: center;
  font-size: toRem(12px);
  font-weight: $font-weight-bold;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}

// ------------------------------------------
// Infinite
//-------------------------------------------
.rc-infinity-symbol {
  font-size: $h2-font-size;
  line-height: 0.7;
}

// ------------------------------------------
// ENDS OF RIGHT (voir pour meilleur endroit)
//-------------------------------------------
.rc-end-rights {
  background-color: $darker;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  &--text {
    padding: 2rem 1rem;

    h2 {
      margin-bottom: 0;
    }

    button {
      margin-top: 1rem;
      svg.rc-remove {
        margin-top: -1px;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  //End of right
  .rc-end-rights {
    background: radial-gradient(
        circle,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      url("./assets/img/th_end_rights.svg");
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 16 / 9;

    &--text {
      padding: 2rem;
    }
  }
}
