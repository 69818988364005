@import "@/libscss/sites/webtelly/variables/_bootstrap-custom"; @import "@/libscss/sites/webtelly/variables/_globals";

.rc-skip-nav-trigger {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(-120px);
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;

  &:focus {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
