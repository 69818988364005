// ------------------------------------------
// Rating
//-------------------------------------------
.rc-rating {
  //By default, light (ex.: Curio)
  --rc-rating-border: 2px solid #{$light};
  --rc-rating-bg: white;
  --rc-rating-star-empty: black;
  --rc-rating-star-full: black;
  --rc-rating-star-empty-disabled: #{$light};

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.5rem 1rem 0.75rem;
  border: var(--rc-rating-border);
  border-radius: 4px;
  background-color: var(--rc-rating-bg);

  .rc-btn-close {
    margin-top: -0.2rem;
    margin-right: -0.7rem;
  }

  .form-group {
    margin-bottom: unset;

    .col-form-label {
      flex-basis: 100%;
      font-size: $font-size-lg;
      font-weight: normal;
      padding-right: 0.85rem;
      padding-bottom: 0.2rem;

      + .col {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .b-rating {
          margin-right: 0.7rem;
        }

        .rc-btn-submit-rating {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .b-rating {
    padding: 0;
    background: unset;
  }
  .b-rating-star {
    padding: 0 0.35rem;
    font-size: 170%;
    &:first-child {
      padding-left: 0;
    }
    &-full {
      .b-rating-icon {
        color: var(--rc-rating-star-full);
      }
    }
    &-empty {
      .b-rating-icon {
        color: var(--rc-rating-star-empty);
      }
    }
  }
  .b-rating.disabled,
  .b-rating:disabled {
    .b-rating-star {
      padding: 0 0.25rem;
      font-size: 100%;
      &-full {
        .b-rating-icon {
          color: var(--rc-rating-star-full);
        }
      }
      &-empty {
        .b-rating-icon {
          color: var(--rc-rating-star-empty-disabled);
        }
      }
    }
  }
  //Hover and focus
  .b-rating:not(.disabled):not(.readonly):focus:not(:hover)
    .b-rating-star.focused
    .b-rating-icon,
  .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
    transform: scale(1.3);
  }

  //rc-rating-thanks
  &.rc-rating-thanks {
    padding-top: 0.75rem;
    padding-bottom: 0.25rem;
    > .row {
      flex-wrap: nowrap;
      .rc-col-thanks {
        flex-shrink: 1;
        padding-left: 5px;
      }
      .b-rating {
        margin-left: -2px;
        margin-top: -5px;
      }
    }
    .rc-thanks-msg {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }
}

@include media-breakpoint-up(sm) {
  .rc-rating {
    .form-group {
      .col-form-label {
        + .col {
          flex-wrap: nowrap;
          .b-rating {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .rc-rating {
    padding: 0.5rem 1.25rem;
    align-items: center;

    .form-group {
      .col-form-label {
        flex-basis: unset;
        margin-bottom: unset;
        padding-bottom: 0.535rem;

        + .col {
          .rc-btn-submit-rating {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }

    .b-rating-star {
      padding: 0 0.25rem;
    }
    .rc-btn-close {
      align-self: self-start;
      margin-top: unset;
    }

    &.rc-rating-thanks {
      padding: 0.5rem 1.25rem;

      > .row {
        .b-rating {
          padding-left: 0.5rem;
          margin-left: 0.875rem;
          margin-top: 0;
          border-left: var(--rc-rating-border) !important;
          border-left-width: 1px !important;
        }
        .rc-col-thanks {
          display: flex;
          align-items: center;
        }
      }
      .rc-thanks-msg {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}
