// ------------------------------------------
// Vue Autosuggest
//-------------------------------------------
.rc-autosuggest-container {
  //By default, light (ex.: Curio)
  --rc-autosuggest-result-bg: white;
  --rc-autosuggest-result-item-bg-hover: #{$gray-200};
  --rc-autosuggest-result-item-bg-active: #{$gray-300};
  --rc-autosuggest-result-item-border: 1px solid #{$gray-300};
  --rc-autosuggest-no-result-color: #{$text-muted};
  --rc-color-error: #{$danger};

  .autosuggest {
    &__results-container {
      position: relative;
      width: 100%;
    }
    &__results {
      position: absolute;
      z-index: 2;
      width: 100%;
      max-height: toRem(200px);
      border: 0 !important;
      border-radius: 0.25rem;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      background: var(--rc-autosuggest-result-bg);
      overflow-y: auto;
      ul {
        margin-bottom: 0;
      }
    }
    &__results-item {
      cursor: pointer;
      padding: 0.4rem 0.75rem;
      border-bottom: var(--rc-autosuggest-result-item-border);

      &:active,
      &:hover,
      &:focus {
        background-color: var(--rc-autosuggest-result-item-bg-hover);
      }
      &--highlighted {
        background-color: var(--rc-autosuggest-result-item-bg-active);
      }
    }
    &__no-results {
      padding: 0.6rem 0.75rem;
      color: var(--rc-autosuggest-no-result-color);
      + ul[role="listbox"] {
        display: none;
      }
    }
  }
}
