:root {
  //Css properties définies dans les 2 sites (distribution)
  // --header-background-color
  // --height-header
  // --footer-background-color

  // --padding-x
  // --padding-top
  // --padding-bottom

  //Form (dossier shared) (distribution, conduit, curio)
  --rc-error-line-height: 1.3571428571;
  --rc-error-font-size: 87.5%;
  --rc-form-label-font-weight: normal;
  --rc-form-card-box-shadow: none;
}
