@import "@/libscss/sites/webtelly/variables/_bootstrap-custom"; @import "@/libscss/sites/webtelly/variables/_globals";

.rc-loader {
  .rc-text-loaded {
    display: none;
  }
  &.loading-done {
    .rc-text-loading {
      display: none;
    }
    .rc-text-loaded {
      display: block;
    }
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
