// ------------------------------------------
// Listing (with filters)
//-------------------------------------------
// ------------------------------------------
// List View
//-------------------------------------------
.rc-list-view {
  > ul > li {
    border-bottom: var(--rc-border-base);
    margin-bottom: toRem(15px);
    padding-bottom: toRem(15px);
  }
}
// ------------------------------------------
// Grid View
//-------------------------------------------
.rc-grid-view {
  > ul > li {
    display: flex;
  }
}
// ------------------------------------------
// Row : nbItems, view mode, filters
//-------------------------------------------
.rc-container-list-options {
  margin-top: 0.5rem;
  margin-bottom: toRem(15px);
  padding-bottom: 0.6rem;
  border-bottom: var(--rc-border-base);
  border-bottom-width: 2px;

  > .row {
    min-height: toRem(38px);
    align-items: center;
  }

  .rc-titles-count {
    font-weight: $font-weight-bold;
    text-transform: lowercase;
    line-height: 1.1;
    margin-bottom: 0;

    .rc-total-count {
      color: var(--text-muted);
      font-weight: $font-weight-base;
      font-size: $font-size-sm;
      white-space: nowrap;
    }
  }

  //Btn batch select
  svg.rc-check-box {
    margin-top: -10px;
  }

  .col-form-label {
    font-weight: $font-weight-bold;
  }
}

@include media-breakpoint-up(sm) {
  .rc-container-list-options {
    .rc-titles-count {
      font-size: $h3-font-size;
      .rc-total-count {
        font-size: $h3-font-size;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .rc-container-list-options {
    margin-top: 0.75rem;
  }
}

// ------------------------------------------
// Container SEARCH & FILTERS
//-------------------------------------------
.rc-container-search-filters {
  margin-bottom: 0.25rem;
}
@include media-breakpoint-up(md) {
  .rc-container-search-filters {
    .rc-search-wrapper {
      .rc-form-search {
        margin-right: 0.25rem;
      }
    }
  }
}

// ------------------------------------------
// COLUMN BUTTON ADD OPTIONS
//-------------------------------------------/
.rc-col-add-options {
  .btn {
    max-width: unset;
  }
}

// ------------------------------------------
// LOAD MORE
//-------------------------------------------/
.btn.rc-btn-load-more {
  max-width: unset;
}
// ------------------------------------------
// BATCH SELECTION (grid view and list view)
//-------------------------------------------/
.rc-grid-view,
.rc-list-view {
  .custom-control {
    padding-left: 0;
    width: 100%;
  }
  input[type="checkbox"] {
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 0;
    + label {
      width: 100%;
      z-index: 1;
      padding: 0.5rem;
      border: 3px solid transparent;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.1);

      &::before,
      &::after {
        display: none;
      }
    }
    &:checked + label {
      border: 3px solid var(--primary);
    }
  }
}
.rc-grid-view {
  .custom-control {
    .rc-grid-unit {
      margin-bottom: 1rem;
    }
    margin-bottom: 1rem;
    height: calc(100% - 1rem);
  }

  input[type="checkbox"] {
    + label {
      height: 100%;
    }
  }
}
.rc-list-view {
  .custom-control {
    width: 100%;
  }

  input[type="checkbox"] {
    + label {
      width: 100%;
    }
  }
}
// ------------------------------------------
// 'CART' for batch selections (default white/light)
//-------------------------------------------
.rc-batch-cart {
  z-index: 1000;
  position: fixed;
  bottom: 1rem;
  left: 5%;
  width: 90%;
  border-radius: $border-radius;
  color: black;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
  .btn-link {
    padding-right: 0;
    padding-left: 0;
    border: 0;
  }

  .rc-wrapper-btn-badge {
    position: relative;
    .badge {
      font-size: $h5-font-size;
      position: absolute;
      top: toRem(-15px);
      right: toRem(-15px);
    }
  }

  .rc-btn-toggle {
    text-align: left;
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem calc(1rem - 2px);
    color: black;
    font-weight: $font-weight-bold;
    background-color: white;
    border-color: white;

    .rc-chevron-up-bold,
    .rc-chevron-down-bold {
      margin-right: -0.325rem;
    }

    &[aria-expanded="true"] {
      .rc-chevron-up-bold {
        display: inline-block;
      }
      .rc-chevron-down-bold {
        display: none;
      }
    }
    &[aria-expanded="false"] {
      .rc-chevron-up-bold {
        display: none;
      }
      .rc-chevron-down-bold {
        display: inline-block;
      }
    }
  }
  &--collapse {
    border-top: 1px solid $gray-300;
    padding: 0.5rem 0;
  }
  &--actions {
    border-bottom: 1px solid $gray-300;
    border-bottom-width: 2px;
    padding: 0 1rem 0.5rem;

    .rc-btn-clear {
      color: black;
    }
  }

  .rc-list-batch {
    margin-bottom: 0;
    margin-right: 0.5rem;

    li {
      border-bottom: 1px solid #{$gray-300};
      padding: 0 1rem 0.5rem;
      margin-bottom: 0.5rem;
      &:first-child {
        padding-top: 0.75rem;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .rc-container-overflow {
    @include overflow-gradient(toRem(220px));

    &--border {
      border-bottom: 1px solid $gray-300;
      border-bottom-width: 2px;
      border-radius: 4px;
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
    background: $gray-300;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $gray-600;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $gray-700;
  }
}
@include media-breakpoint-up(sm) {
  .rc-batch-cart {
    left: calc(50% - 300px);
    width: 100%;
    max-width: 600px;
  }
}
