// ------------------------------------------
// CAROUSEL (Hooper)
//-------------------------------------------
.rc-carousel {
  margin-bottom: 1.5rem;

  &--header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;

    h2,
    h3,
    h4 {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  &--body {
    position: relative;

    ul li.hooper-slide {
      article {
        margin-bottom: toRem(5px);
      }
    }
  }

  &--slides {
    overflow: hidden;
  }

  &--controls {
    display: none;

    button:first-of-type {
      margin-right: 1.5rem;
    }
  }

  // SKIP buttons for carousel
  .rc-btn-skip-to-start,
  .rc-btn-skip-to-end {
    position: absolute;
    top: 0.25rem;
    display: block;
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    color: black;
    background-color: white;

    &:focus {
      opacity: 1;
      overflow: visible;
      pointer-events: all;
    }
  }
  .rc-btn-skip-to-end {
    left: toRem(5px);
  }
  .rc-btn-skip-to-start {
    right: toRem(5px);
  }

  .hooper {
    height: unset;
  }
}

@include media-breakpoint-up(md) {
  .rc-carousel {
    &--controls {
      display: block;
      position: absolute;
      right: 0;
    }
  }
}
@include media-breakpoint-up(lg) {
  .rc-carousel {
    &--controls {
      top: toRem(-50px);
    }
  }
}
