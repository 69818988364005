.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

// Spin
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.spinning {
  -webkit-animation-name: spin;
  animation-name: spin;
}

// Animations Vue
.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.4s ease;
  }
  &-enter,
  &-leave-to {
    /* .fade-leave-active below version 2.1.8 */
    opacity: 0;
  }
}

.slide {
  &-enter-active {
    transition: all 0.3s ease;
  }
  &-enter {
    transform: translateX(50px);
    opacity: 0;
  }
  &-enter-to {
    transform: translateX(0px);
    opacity: 1;
  }
  &-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  &-leave {
    transform: translateX(0px);
    opacity: 1;
  }
  &-leave-to {
    transform: translateX(-50px);
    opacity: 0;
  }
}
.slide-fade {
  &-enter-active {
    transition: all 0.2s ease;
  }
  &-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  &-enter,
  &-leave-to {
    /* .slide-fade-leave-active below version 2.1.8 */
    transform: translateY(10px);
    opacity: 0;
  }
}

.slide {
  &-left {
    &-enter-active {
      transition: all 0.3s ease;
    }
    &-enter {
      transform: translateX(50px);
      opacity: 0;
    }
    &-enter-to {
      transform: translateX(0px);
      opacity: 1;
    }
    &-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }
    &-leave {
      transform: translateX(0px);
      opacity: 1;
    }
    &-leave-to {
      transform: translateX(-50px);
      opacity: 0;
    }
  }
  &-right {
    &-enter-active {
      transition: all 0.3s ease;
    }
    &-enter {
      transform: translateX(-50px);
      opacity: 0;
    }
    &-enter-to {
      transform: translateX(0px);
      opacity: 1;
    }
    &-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }
    &-leave {
      transform: translateX(0px);
      opacity: 1;
    }
    &-leave-to {
      transform: translateX(50px);
      opacity: 0;
    }
  }
  &-down {
    &-enter-active {
      transition: all 0.3s ease;
    }
    &-enter {
      transform: translateY(-24px);
      opacity: 0;
    }
    &-enter-to {
      transform: translateY(0px);
      opacity: 1;
    }
    &-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }
    &-leave {
      transform: translateY(0px);
      opacity: 1;
    }
    &-leave-to {
      transform: translateY(-24px);
      opacity: 0;
    }
  }
}
