// ------------------------------------------
// List of filters
//-------------------------------------------
.rc-list-filters {
  --rc-btn-clear-color: #{$text-muted};
  --rc-btn-clear-font-weight: #{$font-weight-bold};
  --rc-btn-filter-color: #{$black};
  --rc-btn-filter-font-size: #{$font-size-sm};
  --rc-btn-filter-font-weight: #{$font-weight-normal};

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;

  li {
    max-width: 100%;
  }

  .btn {
    margin: 0.25rem 0.5rem 0.25rem 0;
    &.rc-btn-clear {
      color: var(--rc-btn-clear-color);
      font-weight: var(--rc-btn-clear-font-weight);
    }
  }
}
// ------------------------------------------
// Btn Filter applied (with X for closing)
//-------------------------------------------/
.rc-btn-filter {
  @extend .rc-ellipsis-simple;
  position: relative;
  max-width: 100%;
  font-size: var(--rc-btn-filter-font-size);
  font-weight: var(--rc-btn-filter-font-weight);
  color: var(--rc-btn-filter-color);
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;

  span.rc-filter-name {
    @extend .rc-ellipsis-simple;
  }

  span.rc-close-filter {
    margin-left: 0.6rem;
    margin-right: -5px;
  }
}

// ------------------------------------------
// DROPDOWN FILTERS
//-------------------------------------------
.rc-dropdown-filters {
  --rc-menu-border-color: #{$light};

  .btn.dropdown-toggle {
    margin-right: 0.75rem;
  }
  .dropdown-menu {
    width: toRem(290px);
    padding-bottom: 0;
    padding-top: 0.25rem;
    margin-top: 0.5rem;
    li {
      width: 100% !important;
    }
  }

  .custom-checkbox {
    padding: 0.5rem 1.25rem 0.5rem 3.25rem;
    border-bottom: 1px solid var(--rc-menu-border-color);

    &:last-of-type {
      border-bottom: 0;
    }

    .custom-control-label {
      &::before,
      &::after {
        left: -1.8rem;
      }
    }
  }

  .rc-checkbox-group-overflow {
    border: 0px;
    border-radius: 0px;

    .custom-checkbox {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  //First level
  .rc-filters-menu {
    ul li {
      padding-right: 0;
      padding-left: 0;
      border-bottom: 2px solid var(--rc-menu-border-color);

      &:last-child {
        border-bottom: 0;
      }
      button.dropdown-item {
        padding: 0.7rem 1.5rem;
        white-space: unset;
      }
    }
  }
  //Second level
  .rc-filters-detail {
    padding-bottom: 0.5rem;

    header {
      padding: 0.5rem 1rem 0.5rem 0.7rem;
      display: flex;
      align-items: flex-start;

      h3 {
        margin-top: 0.3rem;
        margin-bottom: 0;
      }
      .rc-btn-icon.btn-link {
        margin-right: 0.3rem;
        color: var(--blue);
      }
    }
  }
}
