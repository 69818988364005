// ------------------------------------------
// MODAL
//-------------------------------------------
.rc-modal {
  //First : Bootstrap variables
  //Second : Custom properties
  --rc-modal-sm-max-width: #{$modal-sm};
  --rc-modal-border: 2px solid #{$light};
  --rc-modal-bg: white;
  --rc-modal-color: black;
  --rc-modal-color-error: #{$danger};
  --rc-modal-text-muted: #{$gray-600};
  --rc-modal-font-weight-base: #{$font-weight-base};
  --rc-modal-title-font-size: #{$h2-font-size};
  --rc-modal-title-extra-font-size: #{$h3-font-size};
  --rc-modal-warning-font-size: #{$h5-font-size};
  --rc-modal-warning-font-weight: #{$font-weight-bold};

  //For form inside modal
  --rc-modal-input-border-color: #{$gray-600};
  --rc-modal-input-box-shadow: 0 0 0 0.125rem #{$white},
    0 0 0 0.25rem #{$focus-color};

  --rc-modal-label-font-weight: #{$font-weight-bold};
  --rc-modal-vue-slider-rail-color: #{$gray-400};
  --rc-modal-vue-slider-process-color: #{$gray-600};
  --rc-modal-vue-slider-dot-color: #{$gray-600};
  --rc-modal-vue-slider-tooltip-color: #{$secondary};
  --rc-modal-timepicker-button-color: #{$primary};
  --rc-modal-calendar-button-color: #{$primary};

  //Base
  color: var(--rc-modal-color);

  .text-muted {
    color: var(--rc-modal-text-muted) !important;
  }

  //Header
  .modal-header {
    .modal-title {
      font-size: var(--rc-modal-title-font-size);
    }
    .rc-modal-title--extra {
      font-size: var(--rc-modal-title-extra-font-size);
    }
    .rc-btn-close {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-top: -0.7rem;
      margin-right: -0.7rem;
      color: var(--rc-modal-color);
    }
  }
  //Footer
  .modal-footer {
    margin-top: 0.5rem;

    &.rc-footer-cancel-ok {
      justify-content: space-between;
    }
    &.rc-modal-footer-two-rows {
      flex-direction: column;
      border-top: 0;
      margin-top: -0.5rem;
      padding-top: 0;
    }
    button {
      min-width: toRem(125px);
    }

    &.rc-modal-footer-no-border {
      border-top: 0;
      margin-top: 0;
      padding-top: 0.35rem;
      button {
        min-width: unset;
      }
    }
  }
  //Modal sm
  .modal-sm {
    max-width: var(--rc-modal-sm-max-width);
    margin-right: auto;
    margin-left: auto;
  }
  //Warning delete
  .rc-delete-warning {
    text-align: center;
    h3 {
      font-size: var(--rc-modal-warning-font-size);
      font-weight: var(--rc-modal-warning-font-weight);
    }
    svg {
      margin-bottom: 1.25rem;
      margin-right: 0;
    }
  }
}
// ------------------------------------------
// MODAL with form
//-------------------------------------------
.rc-modal-form {
  .modal-body {
    padding: 0.25rem 1rem;

    .rc-form-warning {
      align-items: center;
      border-bottom: var(--rc-modal-border);
      padding: 0.5rem 1.25rem 1rem;
      margin: 0 toRem(-20px) 0.5rem;

      &--text {
        font-weight: var(--rc-modal-warning-font-weight);
      }
    }
  }
  form {
    margin-bottom: 0;
  }

  .row {
    margin: 0;
    padding: 1rem 0;
    border-bottom: var(--rc-modal-border);
    border-bottom-width: 1px;

    .row {
      padding: 0;
      border-bottom: 0;
    }
  }

  //Label
  label,
  .col-form-label {
    font-weight: var(--rc-modal-label-font-weight);
    color: var(--rc-modal-color) !important;
    &.text-muted {
      color: $gray-600 !important;
    }
  }

  //Input
  input {
    max-width: 100% !important;
  }

  .form-control {
    border-color: var(--rc-modal-text-muted);

    &.focus {
      box-shadow: var(--rc-modal-input-box-shadow) !important;
    }
    &:disabled {
      opacity: 0.6;
    }
  }
  //Invalid
  .form-control.is-invalid {
    border-color: var(--rc-modal-color-error);
  }

  //Select
  .custom-select {
    border-color: var(--rc-modal-input-border-color);
    &:disabled {
      color: var(--rc-modal-color);
      opacity: 0.6;
    }
  }

  //File upload
  .custom-file-input {
    ~ .custom-file-label {
      border-color: var(--rc-modal-input-border-color);
    }
    &.is-valid {
      ~ .custom-file-label {
        border-color: var(--rc-modal-input-border-color);
      }
    }
  }

  //Checkbox Group and Radio Group
  .custom-control-label {
    font-weight: var(--rc-modal-font-weight-base);
    &::before {
      border-color: var(--rc-modal-input-border-color) !important;
    }
  }
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          background-color: var(--rc-modal-input-border-color);
        }
      }
    }
    &:focus {
      ~ .custom-control-label {
        &::before {
          box-shadow: var(--rc-modal-input-box-shadow) !important;
        }
      }
    }
  }
  .rc-checkbox-group {
    .custom-checkbox {
      padding: 0 1.5rem 1rem;
      border-bottom: 0;

      &:not(:first-of-type) {
        label {
          margin-top: 0;
        }
      }
    }
  }

  //Checkbox Group Overflow
  .rc-checkbox-group-overflow {
    height: toRem(200px);
    margin-bottom: 0;
    border: 2px solid var(--rc-modal-input-border-color);
    label {
      margin-top: 0;
    }
    .custom-checkbox {
      padding: 0.5rem 1rem 0.5rem 2.25rem;
      border-bottom: var(--rc-modal-border);
      border-bottom-width: 1px;

      &:first-of-type {
        padding-top: 0.25rem;
      }

      &:last-child {
        border: none;
      }
    }
  }

  //Tags
  .b-form-tag-remove {
    text-shadow: none;
    font-weight: var(--rc-modal-font-weight-base);
  }

  // Datepicker

  .b-form-datepicker {
    align-items: center;
    height: toRem(38px);
    > button {
      color: var(--rc-modal-color);
      &:focus,
      .focus {
        box-shadow: none !important;
      }
    }
    &.focus {
      button {
        box-shadow: none;
      }
    }
    label {
      font-weight: var(--rc-modal-font-weight-base);
      padding-top: 0.5rem;
      line-height: 1;
    }
  }

  .b-calendar-nav {
    button {
      color: var(--rc-modal-calendar-button-color);
      &:focus,
      &:hover {
        color: white;
        background-color: var(--rc-modal-calendar-button-color);
      }
    }
  }

  //Disable for date picker
  .b-form-btn-label-control.form-control[aria-disabled="true"] {
    opacity: 0.6;
  }

  //Slider
  .rc-form-group-slider {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    label {
      margin-top: 0;
    }
  }
  .vue-slider {
    padding: 7px 14px !important;

    &-rail {
      background-color: var(--rc-modal-vue-slider-rail-color);
    }
    &-process {
      background-color: var(--rc-modal-vue-slider-process-color);
    }
    &-dot {
      &-handle {
        border: 2px solid var(--rc-modal-vue-slider-dot-color);
        box-shadow: none;
      }
      &-tooltip {
        &-inner {
          background-color: var(--rc-modal-vue-slider-tooltip-color);
          border-color: var(--rc-modal-vue-slider-tooltip-color);
        }
      }
      &:focus,
      &:focus:hover {
        .vue-slider-dot-handle {
          box-shadow: var(--rc-modal-input-box-shadow) !important;
        }
      }
      &:hover {
        .vue-slider-dot-handle {
          box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  //error
  .invalid-feedback {
    margin-top: 0.325rem;
  }
}

.modal-sm {
  .rc-modal-form {
    .modal-footer {
      justify-content: center;
    }

    input[type="text"] {
      max-width: 100%;
    }
  }
}
@include media-breakpoint-up(md) {
  .rc-modal-form {
    .modal-body {
      padding: 0.5rem 1.25rem;
    }
    input[type="text"] {
      margin-top: -2px;
    }
    .rc-btn-manage {
      text-decoration: none !important;
      margin-top: -2px;
    }
  }
}

// ------------------------------------------
// MODAL Confirmation (Cancel or Ok)
//-------------------------------------------
.rc-modal-confirmation {
  .modal-body {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }
    svg {
      margin-right: 1rem;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
}
@include media-breakpoint-up(sm) {
  .rc-modal-confirmation {
    .modal-body {
      font-size: $font-size-lg;
    }
  }
}
// ------------------------------------------
// MODAL PERMALINK
//-------------------------------------------
.rc-modal-permalink {
  .modal-body {
    padding: 1.5rem 1.25rem;
  }

  .rc-input-btn-wrapper,
  input[type="text"] {
    max-width: 100%;
  }
}

// ------------------------------------------
// MODAL SEGMENT
//-------------------------------------------
.rc-modal-segment {
  .modal-body {
    padding: 1rem 0 0;
  }
  .rc-media-accessibility {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    button {
      min-width: 100% !important;
    }
  }

  .rc-segment-media {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-bottom: 0.5rem;

    //Player kaltura - Hide seek-bar
    .playkit-seek-bar {
      display: none;
    }

    .rc-card-unit .rc-card-text {
      margin-bottom: 0.8rem;
      margin-left: 0.75rem;
      .rc-duration {
        position: unset;
        margin-right: 0.25rem;
      }
    }
  }
  .rc-segment-slider {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    .col {
      max-width: toRem(93px);
      label {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
      input {
        text-align: center;
      }
    }
    .rc-duration {
      display: inline-block;
      margin-top: 1.6rem;
      font-weight: normal;
    }

    .vue-slider {
      &-dot-tooltip {
        visibility: hidden !important;
      }
      ~ .btn-primary {
        margin-top: 1.5rem;
        margin-bottom: 1.75rem !important;
      }
    }
  }

  .rc-nav-tabs-sm {
    justify-content: center;
    a.nav-link {
      color: black;
    }
    .nav-item {
      text-align: center;
    }
  }

  .tab-content {
    padding: 1rem 1.25rem 1.75rem;
    background-image: linear-gradient(
      180deg,
      rgba(223, 223, 223, 0.4) 0%,
      rgba(223, 223, 223, 0.1) 30%,
      rgba(223, 223, 223, 0) 50%
    );
  }
}
