.rc-border-separator-bottom {
  border-bottom: var(--rc-border-separator);
}
.text-size-sm {
  font-size: $font-size-sm;
}

.rc-warning-box,
.rc-danger-box {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem 0;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.7);
  //background-color: #bec5c8;
  color: $dark;

  .rc-icon-svg {
    font-size: 125%;
    vertical-align: text-bottom;
  }

  &--dark {
    background-color: $darker;
    color: white;
  }
}
