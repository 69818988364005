// ------------------------------------------
// LAYOUT FOR MONITOR CHANNEL
//-------------------------------------------

.rc-l-monitor {
  --rc-height-colQueue: 142px;
  --rc-px-min: 8px;

  .rc-container-list-options {
    margin-top: 0;
    h2 {
      margin-bottom: 0.625rem;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
  //PLAYING NOW
  .rc-col-playing-now {
    padding: 1rem 0;
    border-bottom: var(--rc-border-separator);
    .rc-container-list-options {
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0.2rem;
      padding-bottom: 0.5rem;
    }

    .rc-content-scroll {
      padding: 0 1rem;
    }
    .rc-wrapper-video {
      position: relative;
      .tag-now {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
        z-index: 100;
      }
    }
    //Playing now
    .rc-playing-now {
      position: relative;
      width: calc(100% + toRem(12px));
      margin-left: toRem(-6px);
      margin-right: toRem(-6px);
      padding: toRem(5px);
      border-radius: toRem(10px);
      color: white;

      .rc-line-marker {
        z-index: 10;
        left: var(--rc-playing-now-pourcent);
        width: 0;
        top: 0px;
        height: 0;
        position: absolute;
        transition: transform 100ms;
        margin-left: -7.5px;

        .top {
          height: toRem(60px);
          border-color: transparent #87a5ae transparent transparent;
          border-style: solid;
          border-width: 1.5px;
          margin-left: 5.75px;
          margin-top: 0;
        }
        .bottom {
          border-color: transparent transparent var(--white) transparent;
          border-style: solid;
          border-width: 0px 8px 12px 8px;
        }
      }

      .rc-disclaimer {
        padding: toRem(10px) toRem(5px) toRem(5px);
        font-style: normal;
        font-size: $font-size-sm;
        color: rgba($white, 0.8);
      }

      .rc-btn-occ--now {
        background-image: linear-gradient(
          90deg,
          var(--blue) var(--rc-playing-now-pourcent),
          #0b6380 var(--rc-playing-now-pourcent)
        );
        border: 0;
        border-radius: toRem(5px);
        height: toRem(70px);
        line-height: 1.25;
        cursor: auto !important;
        .rc-occ-title {
          @extend .rc-ellipsis-simple;
          padding-right: 1.5rem;
        }
        .rc-occ-time {
          display: block !important;
        }
        .rc-icon-container {
          background-color: var(--rc-color-event);
        }
        &:focus,
        &:active {
          padding-top: 0.375rem;
          border: 0 !important;
        }
      }
    }
    ul.rc-list-data {
      .list-group-item {
        border-bottom: var(--rc-border-base);
      }
    }
  }
  //QUEUE
  .rc-col-queue {
    padding: 1rem;
    .rc-container-list-options {
      padding-bottom: 1rem;
      .row {
        .rc-col-day-title {
          flex-shrink: 1;
          overflow: hidden;
        }
        h2 {
          @extend .rc-ellipsis-simple;
          margin-bottom: 0;

          .rc-skeleton {
            width: 150px;

            height: 23px;
          }
        }

        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  //QUEUE - Timeline
  .rc-col-timeline {
    //Timeslot
    .rc-timeslot--base {
      height: calc(var(--rc-px-min) * var(--rc-base-min));
      border-bottom: 1px solid var(--blue);
      background-color: rgba(255, 255, 255, 0.03);

      &:last-of-type {
        background-image: none;
      }
    }

    .rc-content-scroll {
      min-height: 330px;
      margin-bottom: 0;
    }
    .rc-timeline {
      padding-top: 4px;

      &--times {
        flex-grow: 0;
        flex-basis: auto;
        margin-top: 0;
        text-transform: lowercase;
        white-space: nowrap;
      }
      .rc-timeslot {
        font-size: $font-size-sm;
        height: calc(var(--rc-px-min) * var(--rc-min));
      }
      &--date {
        position: relative;
        top: -1.75rem;
        padding-bottom: 0.25rem;
        border-bottom: 1px solid var(--active);
        font-size: $font-size-base;
        margin-bottom: -1.45rem;
      }
      &--occs {
        position: relative;
        .rc-list-occ {
          margin-top: 0;
          margin-bottom: 0;
          li {
            position: absolute;
            left: -10px;
            width: calc(100% + 12px);
            height: 100%;
            padding-bottom: 3px;
            border: 1px solid transparent;
            z-index: 1;
          }
          li.rc-occ-active {
            z-index: 200 !important;
          }
          li.rc-occ-conflicted {
            + .rc-occ-conflicted {
              width: calc(100% + 7px);
              margin-left: toRem(6px);
              border: 3px solid var(--darker);
              border-radius: 6px;
              background-color: var(--dark);
              box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
              z-index: 2;

              + .rc-occ-conflicted {
                margin-left: toRem(14px);
                width: calc(100% + 3px);
                z-index: 3;

                + .rc-occ-conflicted {
                  z-index: 4;

                  + .rc-occ-conflicted {
                    z-index: 5;
                  }
                }
              }
            }
          }
          li:not(.rc-occ-conflicted) {
            z-index: 10;
          }
          li.rc-occ-past {
            filter: brightness(80%);
          }
          .rc-btn-occ {
            background-color: var(--dark);

            &:hover {
              background-color: color-mix(in srgb, var(--dark), #000 10%);
            }

            .rc-icon-container {
              background-color: var(--rc-color-event);
            }

            &.rc-btn-base {
              .rc-icon-container {
                background-color: transparent;
              }
            }

            &.rc-btn-overnight {
              filter: brightness(80%);
              background: repeating-linear-gradient(
                45deg,
                var(--dark),
                var(--dark),
                10px,
                color-mix(in srgb, var(--dark), #fff 10%) 10px,
                color-mix(in srgb, var(--dark), #fff 10%) 20px
              );
            }
          }
        }
      }
    }
  }

  //QUEUE - Detail occ
  .rc-col-occ-detail {
    margin-top: 2rem;
    background-color: rgba(255, 255, 255, 0.03);

    header {
      h3 {
        margin-bottom: 0.25rem;
      }
    }
  }
  //PLAYLIST
  .rc-toolbar-playlist {
    padding: 1rem 0;
    border-bottom: var(--rc-border-base);

    &--help {
      padding-top: 0.75rem;
      font-size: $font-size-sm;
    }
  }

  .rc-tab-live-stream {
    .rc-list-data {
      .list-group-item {
        word-wrap: break-word;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .rc-l-monitor {
    .rc-col-queue {
      padding: 1rem;
      .rc-container-list-options {
        .row {
          h2 {
            .rc-skeleton {
              width: 350px;
              height: 29px;
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .rc-l-monitor {
    .rc-col-playing-now {
      padding: 1.5rem 0.5rem;
    }
    .rc-col-queue {
      padding: 1.5rem;
    }
  }
}
@include media-breakpoint-up(xl) {
  .rc-l-monitor {
    .rc-col-playing-now {
      padding: 2rem 1rem;
    }
    .rc-col-queue {
      padding: 2rem;
    }
    .col-xl-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-xl-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    //QUEUE - Timeline
    .rc-col-timeline {
      padding-right: 1rem;
      .rc-content-scroll {
        padding: 0 0.5rem;
        margin-bottom: 0;
      }
    }

    .rc-col-occ-detail {
      margin-top: 0;
    }

    .rc-container-list-build {
      .rc-row-unit {
        flex-wrap: nowrap;
        .h5 {
          font-size: $font-size-base;
        }
        .rc-list-border {
          font-size: $font-size-sm;
        }
        > div:last-child {
          flex-grow: 0;
        }
      }
    }
  }
}

//Exception .rc-grid-fit-viewport for screen between 1200px and 1400px (see grid.scss)
@media (min-width: 1200px) and (max-width: 1400px) {
  .rc-grid-fit-viewport {
    main {
      max-height: unset;
    }
    .rc-row-fit-viewport {
      height: unset;
      overflow: visible;
    }
    .rc-col-queue.rc-col-content {
      height: toRem(500px);

      .rc-content-scroll {
        max-height: unset;
        flex-grow: 0;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
    .rc-col-playing-now.rc-col-content {
      height: unset;
      .rc-content-scroll {
        max-height: 100%;
      }
    }
  }
}
@media (min-height: 600px) and (min-width: 1400px) {
  .rc-grid-fit-viewport .rc-col-queue .rc-col-content {
    height: calc(
      100vh - var(--rc-height-headerFooter) - var(--rc-height-colQueue)
    );
  }
}
@media (min-height: 900px) and (min-width: 1400px) {
  .rc-grid-fit-viewport .rc-col-queue .rc-col-content {
    height: calc(
      100vh - var(--rc-height-headerFooter) - var(--rc-height-colQueue) - 1.5rem
    );
  }
}

//1400px
@include media-breakpoint-up(xxl) {
  .rc-l-monitor {
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    .col-xl-8 {
      flex: 0 0 66.66666%;
      max-width: 66.66666%;
    }

    .rc-col-playing-now {
      border-bottom: 0;
      border-right: var(--rc-border-separator);
    }

    .rc-container-list-build {
      .rc-row-unit {
        .h5 {
          font-size: $font-size-lg;
        }
      }
    }
  }
}
@include media-breakpoint-up(xxxl) {
  .rc-l-monitor {
    .rc-container-list-build {
      .rc-row-unit {
        .h5 {
          font-size: $font-size-lg;
        }
        .rc-list-border {
          font-size: $font-size-base;
        }
      }
    }
  }
}
