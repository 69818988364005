@import "@/libscss/sites/webtelly/variables/_bootstrap-custom"; @import "@/libscss/sites/webtelly/variables/_globals";

.notifications {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  max-width: 350px;
  width: 100%;

  z-index: 9999;

  .list-enter-active,
  .list-leave-active {
    transition: opacity 0.3s;
  }

  .list-enter,
  .list-leave-active {
    opacity: 0;
  }
}
