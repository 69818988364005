// ------------------------------------------
// LAYOUT FOR SCHEDULE CHANNEL
//-------------------------------------------
.rc-l-schedule {
  //For view week
  --rc-px-min: 4.5px;

  //For day
  .tabs {
    --rc-px-min: 6px;
  }

  &--nav {
    padding: 1rem;
    background-color: var(--dark);
    border-bottom: var(--rc-border-base);
  }
  a[role="tab"].nav-link {
    padding: 0.5rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      text-decoration: none !important;
      color: rgba(255, 255, 255, 0.75);
    }
    &.active {
      background-color: var(--blue-light);
      outline: 4px solid var(--blue-light);
    }
  }
  &--filters {
    padding: 0.5rem 0.5rem 1rem;
    background-color: var(--dark);
    border-bottom: var(--rc-border-base);
    .btn.dropdown-toggle {
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
    }
  }
  &--days {
    position: sticky;
    top: -1px;
    z-index: 10;
    padding: 0 0.5rem;
    background-color: var(--dark);
    border-bottom: 4px solid var(--blue-light);

    .rc-tag-today {
      padding: 0 0.25rem;
      border-radius: 10px;
      color: var(--dark);
      text-transform: uppercase;
      background-color: var(--text-muted);
    }
    &::after {
      content: "";
      display: block;
      height: toRem(8px);
      width: 100%;
      position: absolute;
      bottom: toRem(-12px);
      left: 0;
      z-index: 2;

      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &--day {
    &.btn {
      padding: 0;
      border: 0;
      text-decoration: none;
      line-height: unset;
      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
  &--day-title {
    font-size: toRem(10px);
    color: var(--text-muted);
  }
  &--day-number {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
  }
  &--body {
    padding: 0.5rem 1rem 8rem 1rem;
  }
  &--times {
    flex-grow: 0;
    flex-basis: auto;
    text-transform: lowercase;
    white-space: nowrap;
  }

  .rc-timeslot {
    font-size: $font-size-sm;
    height: calc(var(--rc-px-min) * var(--rc-min));
  }
  .rc-time-marker {
    height: 0px;
    border-bottom: 1px solid var(--active);
    width: calc(100vw - 50px);
    position: absolute;
    left: 10px;
    z-index: 100;
    opacity: 1;
  }

  &--occs {
    position: relative;
    --rc-color-event: #{$dark};

    .rc-list-occ {
      margin-top: 0;
      margin-bottom: 0;
      > li {
        position: absolute;
        left: -8px;
        width: calc(100% + 8px);
        height: 100%;
        padding-bottom: 1px;
        border: 1px solid transparent;
        z-index: 1;
      }

      li.rc-occ-active {
        z-index: 200 !important;
      }

      li.rc-occ-conflicted {
        .rc-btn-occ {
          background-color: color-mix(in srgb, var(--rc-color-event), #000 10%);
        }
        + .rc-occ-conflicted {
          width: calc(100% + 7px);
          margin-left: toRem(6px);
          border: 3px solid var(--dark);
          border-bottom-width: 1px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
          z-index: 2;

          + .rc-occ-conflicted {
            margin-left: toRem(14px);
            width: calc(100% + 3px);
            z-index: 3;
            + .rc-occ-conflicted {
              z-index: 4;
              + .rc-occ-conflicted {
                z-index: 5;
              }
            }
          }
        }
      }

      li:not(.rc-occ-conflicted) {
        z-index: 10;
      }
      li.rc-occ-past {
        filter: brightness(80%);
      }
    }

    //Base
    .rc-timeslot--base {
      height: calc(var(--rc-px-min) * var(--rc-base-min));
      border-bottom: var(--rc-border-base);
      background-color: var(--dark);

      .rc-btn-occ {
        position: relative;
        top: 1.5px;
        height: calc(100% - 4px);

        //cursor: unset;
        //à commenter quand il y aura un click
        &:hover,
        &:focus,
        &:active {
          border-color: transparent !important;
          background-color: unset;
          box-shadow: none !important;
        }
      }
      &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      &:last-of-type {
        background-image: none;
      }
      &:not(:first-of-type) {
        .rc-btn-occ {
          .rc-occ-title {
            font-weight: $font-weight-base;

            color: rgba($white, 0.3);
          }
          .rc-icon-svg,
          .rc-occ-time {
            display: none;
          }
        }
      }
    }
    //Buttons
    .rc-btn-occ {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;

      &.rc-btn-overnight {
        filter: brightness(80%);
        border-color: color-mix(in srgb, var(--rc-color-event), #fff 10%);
        background: repeating-linear-gradient(
          45deg,
          var(--rc-color-event),
          var(--rc-color-event),
          10px,
          color-mix(in srgb, var(--rc-color-event), #000 20%) 10px,
          color-mix(in srgb, var(--rc-color-event), #000 20%) 20px
        );
        &--line {
          height: 0.5rem;
          margin-top: -0.5rem;
          padding: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }

  //Buttons (mode list (day))
  .tabs {
    .rc-btn-occ {
      .rc-occ-title {
        font-size: $font-size-base;
      }
      .rc-occ-content-name {
        padding: toRem(2px) 1rem;
        font-size: $font-size-sm;
        line-height: 1.55;
        border-radius: toRem(14px);
        background-color: color-mix(in srgb, var(--rc-color-event), #000 20%);
      }
    }

    ul.rc-list-border li {
      font-size: $font-size-sm;
      margin-bottom: 0.25rem;
    }

    .rc-occ-desc {
      max-width: toRem(900px);
      &--two-line {
        // @extend .rc-ellipsis-simple;
        word-break: break-word;
        @include line-clamp(2, var(--text-muted));
        white-space: unset;
      }
      &--four-lines {
        word-break: break-word;
        @include line-clamp(4, var(--text-muted));
        white-space: unset;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .rc-l-schedule {
    &--day-title {
      font-size: $font-size-sm;
    }
    &--days {
      padding: 0rem 1rem;
    }
    .rc-tag-today {
      padding: 0 1rem;
    }
  }
}
@include media-breakpoint-up(lg) {
  .rc-l-schedule {
    &--nav {
      padding: 1rem 1.5rem;
      border-bottom: var(--rc-border-base);
    }
    .rc-time-marker {
      width: calc(100vw - 60px);
    }
    //LIST VIEW (tabs)
    .tabs {
      .rc-btn-occ:not(.btn-dark) {
        .rc-occ-title {
          font-size: $font-size-lg;
        }
      }
    }
  }
}
//1200px
@include media-breakpoint-up(xl) {
  .rc-l-schedule {
    &--filters {
      padding: 0.5rem 1.5rem;
    }
    &--days {
      position: relative;
      top: unset;
      padding: 0.5rem 1rem;
      //To align with scrollable content (scrollbar 10px)
      padding-right: calc(1rem + 10px);

      .rc-tag-today {
        font-size: toRem(14px);
        line-height: 1.55;
      }
    }

    &--day-title {
      font-size: $font-size-base;
    }
    &--day-number {
      font-weight: $font-weight-bold;
    }

    &--body {
      padding: 1rem 1rem 8rem 1rem;
    }
    &--times {
      flex-basis: 5.5rem;
      &:last-of-type {
        display: none;
      }
    }

    .rc-time-marker {
      width: calc(100vw - 100px);
      left: 40px;
    }
    &--occs {
      position: relative;
    }

    //LIST VIEW (tabs)
    .tabs {
      --rc-height-navAndDays: 233px;
      --rc-px-min: 7px;

      .rc-content-scroll {
        max-height: calc(
          100vh - var(--rc-height-headerFooter) - var(--rc-height-navAndDays)
        );
      }
      .rc-l-schedule--days {
        padding: 0 1rem 0 5.75rem;
      }

      a[role="tab"].nav-link {
        padding-left: 0;
        padding-right: 0;
      }

      .rc-btn-occ:not(.btn-dark) {
        .rc-occ-title {
          font-size: $h4-font-size;
        }
        .rc-occ-content-name {
          font-size: $font-size-base;
        }
        ul.rc-list-border li {
          font-size: $font-size-base;
        }
      }
    }
  }
}
//1900px
@include media-breakpoint-up(xxxl) {
  .rc-l-schedule {
    &--times {
      &:last-of-type {
        display: block;
      }
    }
    .rc-time-marker {
      width: calc(100vw - 180px);
    }

    //LIST VIEW (tabs)
    .tabs {
      .rc-l-schedule--days {
        padding: 0rem 6.5rem 0rem 5.75rem;
      }
      .rc-time-marker {
        width: calc(100vw - 130px);
      }
    }
  }
}
