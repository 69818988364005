// ------------------------------------------
// MODAL (and modal with form)
//-------------------------------------------
.rc-modal {
  --rc-modal-border: 2px solid #{$gray-200};
  --rc-modal-timepicker-button-color: #{$blue};
  --rc-modal-calendar-button-color: #{$blue};
  --rc-modal-vue-slider-tooltip-color: #{$dark};

  .btn-link {
    color: black;
  }

  .btn-transparent {
    color: black;
    font-weight: $font-weight-normal;
    &:hover,
    &:focus {
      background-color: $gray-300;
    }
  }

  //Feed warning
  .rc-feed-warning {
    width: 100%;
    flex-grow: 1;
    flex-basis: 100%;
    font-size: $font-size-sm;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  //fieldset
  .rc-fieldset-box {
    --rc-modal-border: 2px solid #{$gray-300};

    position: relative;
    margin-top: 3.75rem;
    background-color: var(--lighter);
    padding: 1rem;
    border-radius: toRem(10px);

    legend {
      position: absolute;
      top: -2.25rem;
      left: 0;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }

    &--intro {
      padding-bottom: 0.5rem;
      //font-size: $font-size-sm;
      //color: var(--rc-modal-text-muted);
    }

    .row:last-of-type {
      border: 0;
    }

    //fieldset imbriqué
    fieldset {
      legend {
        position: unset;
      }
    }
  }

  //list with overflow
  .rc-container-overflow {
    height: toRem(350px);
    padding: 0 1rem;
    border: 2px solid var(--rc-modal-input-border-color);
    border-radius: 4px;

    &::after {
      display: none;
    }
    ul li:last-child {
      border-bottom: 0;
    }
  }

  .modal-footer {
    .btn-primary {
      @extend .btn-secondary;
    }
  }
}

//Modal Confirmation
.rc-modal-confirmation .modal-body {
  display: block;
}

@include media-breakpoint-up(sm) {
  .rc-modal-form {
    .rc-progress-label {
      &--name {
        max-width: toRem(275px);
      }
    }
  }
}

//Modal with stepper
.rc-modal-stepper {
  .modal-body {
    padding: 0;
  }
  //List build
  .rc-container-list-build {
    &.rc-container-overflow {
      padding: 0 0 1rem 0;
    }
  }

  ul.rc-list-build > li {
    padding-top: 1rem;
    margin-bottom: 1rem;
    border-top: var(--rc-modal-border);
    border-top-width: 1px;

    &:first-child {
      border-top: 0;
    }

    .row {
      margin: 0;
      padding: 0 1rem;
      border: 0;
    }
    .rc-col-thumbnail {
      font-size: $font-size-sm;
    }
    &.rc-type-ad-break {
      border: 0;
      padding-top: 0.25rem;
      margin-bottom: 0.25rem;
      color: var(--rc-modal-text-muted);

      div {
        &::before,
        &::after {
          background: repeating-linear-gradient(
            45deg,
            #ededed,
            #ededed,
            5px,
            transparent 5px,
            transparent 10px
          );
        }
      }
    }

    .rc-row-unit {
      .h5 {
        @include line-clamp(2, var(--black));
        white-space: unset;
      }
      .rc-list-border {
        color: var(--rc-modal-text-muted);
        margin-top: 2px;
        li {
          border-right: var(--rc-modal-border);
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}
