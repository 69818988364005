// ------------------------------------------
// LISTS
//-------------------------------------------
// ------------------------------------------
// List with border
//-------------------------------------------
ul.rc-list-border {
  @include list-separator-border(rgba(255, 255, 255, 0.25));

  li:empty {
    display: none;
  }
}

// ------------------------------------------
// List badge
//-------------------------------------------
ul.rc-list-badge {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.2rem;

  li {
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;

    .badge {
      font-size: $font-size-sm;
    }

    &:last-child {
      margin-right: 0;
    }
    a {
      text-decoration: none !important;
    }
  }

  li:empty {
    display: none;
  }
}

// ------------------------------------------
// List comma
//-------------------------------------------
ul.rc-list-comma {
  display: inline-flex;
  margin-bottom: 0;
  li {
    margin-bottom: 0;
    &::before {
      content: ",";
      margin: 0 !important;
    }

    &:first-child {
      &::before {
        content: "";
      }
    }
  }
}
// ------------------------------------------
// List Navigation for playlist
//-------------------------------------------
ul.rc-list-nav {
  //margin-bottom: 4rem;
  > li {
    padding: 1rem 0;
    border-bottom: var(--rc-border-base);
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
    .rc-list-nav-unit {
      &--title {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        margin-bottom: 0.25rem;
      }
      &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rc-duration,
      .rc-list-border {
        color: $text-muted;
      }
    }
    a,
    .btn-link {
      width: 100%;
      max-width: 100%;
      padding: 0 0 0 0.5rem;
      border: 0;
      border-left: 4px solid var(--blue-light);
      border-radius: 0;
      text-decoration: none;
      text-align: left;

      &.active {
        border-left-color: var(--active);
      }
      &:hover {
        text-decoration: none !important;

        .rc-list-nav-unit {
          .rc-list-border {
            color: $text-muted;
          }
        }
      }
    }
  }
}

// ------------------------------------------
// List Build Playlist (monitor, and modale update occ episodic)
//-------------------------------------------
.rc-container-list-build {
  padding: 1rem 0;
}

ul.rc-list-build > li {
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-top: var(--rc-border-base);

  &:first-child {
    padding-top: 0;
    border-top: 0;
  }

  &.rc-type-ad-break {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border: 0;
    font-size: $font-size-sm;
    color: var(--text-muted);
    text-transform: uppercase;

    div {
      position: relative;
      display: flex;
      align-items: center;
      flex-grow: 1;
      &::before,
      &::after {
        content: "";
        position: relative;
        flex: 1;
        height: 3px;
        margin: 0 0 0 0.5rem;
        background: repeating-linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1),
          5px,
          transparent 5px,
          transparent 10px
        );
      }
      &::before {
        margin: 0rem 0.5rem 0 0;
      }
    }

    + li {
      border-top: 0;
      &.rc-type-ad-break {
        padding-top: 0.5rem;
      }
    }
  }

  .rc-row-unit {
    flex-wrap: nowrap;
    .rc-col-thumbnail + div {
      padding-left: 0.75rem;
    }
    .h5 {
      margin-bottom: 0.25rem;
      @include line-clamp(2, var(--white));
      white-space: unset;
    }
    a:hover {
      .h5 {
        color: var(--active);
      }
    }
  }
}
