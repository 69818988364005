// ------------------------------------------
// Boostrap Variables
//-------------------------------------------

// Color system

//Grays
$white: #fff;
$gray-100: #fafafa;
$gray-200: #ededed;
$gray-300: #dddddd;
$gray-400: #d1d1d1;
$gray-500: #bebebe;
$gray-600: #757575;
$gray-700: #4d4d4d;
$gray-800: #404040;
$gray-900: #222;
$black: #000;

// Colors
$indigo: #495b99; //Additional RC Colors
$navy: #263362; //Additional RC Colors, *uses for color event
$plum: #3e2f5b; //Additional RC Colors, *uses for color event
$purple: #5a1d69; //*uses for color event
$pink: #9f336c; //*uses for color event
$coral: #bc4b51; //Additional RC Colors, *uses for color event
$red-wine: #831127; //Additional RC colors, *uses for color event //691b2a
$red: #c41f29;
$orange: #fd7e14;
$yellow: #f4ce00;
$green: #2eb67e;
$green-salad: #168164; //Additional RC Colors, *uses for color event
$turquoise: #127a81; //Additional RC Colors,*uses for color event
$green-forest: #0b5a45; //Additional RC Colors, *uses for color event
$blue-duck: #095b7b; //Additional RC Colors, *uses for color event

//Blue colors
$blue-light: #165061; //button
$blue-matt: #043b4e; //list schedule in
$blue: #063140; //colonne
$blue-dark: #042734; //colonne //rgb : 4,39,52
$blue-darker: #051e29;
$blue-black: #000f14; //fin-background
$blue-flash: #1693bf; //use for button and playing now

//white opacity 25% (border)
//text opacity 70% pour text-muted
//For AA contrast on white, dark and plum bg
$focus-color: #02929a;

//Themes colors
//$primary: $yellow;
$primary: $blue-flash;
//$secondary: $blue;
$secondary: $blue-light;
$light: $white;
$lighter: $gray-200;
$dark: $blue-dark;
$darker: $blue-darker;
$active: $yellow;
$success: $green;
$danger: $red;
$warning: #f7b22a;
$info: $blue-flash;
$gray: $gray-600;
$more-muted: rgba($white, 0.5);

$theme-colors: (
  "black": $black,
  "active": $active,
  "darker": $darker,
  "blue": $blue,
  "blue-light": $blue-light,
  "blue-matt": $blue-matt,
  "gray": $gray,
  "indigo": $indigo,
  "lighter": $lighter,
  "more-muted": $more-muted
);

// Customize the light and dark text colors for use in our YIQ color contrast function.
// $yiq-text-dark:             $gray-900 !default;
// $yiq-text-light:            $white !default;

//Spacing
$spacer: 1rem;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.5
  ),
  2: (
    $spacer
  ),
  3: $spacer * 1.5,
  4: (
    $spacer * 2
  ),
  5: (
    $spacer * 2.5
  )
);

//Body
$body-bg: $blue-darker;
$body-color: $white;

//Links
$link-color: $body-color;
$link-decoration: none;
$link-hover-color: $active;
$link-hover-decoration: underline;

// Paragraphs
$paragraph-margin-bottom: 1rem !default;

// Grid breakpoints
// We now have `@include media-breakpoint-up/down/only`.
// Now, instead of writing `@media (min-width: @screen-sm-min) { ... }`, you can write `@include media-breakpoint-up(sm) { ... }`.

// Legacy (à changer éventuellement)
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
//End legacy

//Grid breakpoints
$grid-breakpoints: () !default;
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1900px
  ),
  $grid-breakpoints
);

// Grid containers
//Define the maximum width of `.container` for different screen sizes.
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1820px
  ),
  $container-max-widths
);

//@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 20px;

// Components - Define common padding and border radius sizes and more.
$border-width: 1px;
$border-color: rgba($white, 0.2); //white 20%

$border-radius: 0.25rem; // 4px;
$border-radius-lg: 0.25rem;
$border-radius-sm: 0.25rem;

$component-active-color: $white;
$component-active-bg: $dark;

//Fonts  (see base/typography for more customization)
$enable-responsive-font-sizes: false;
$font-family-base: "Radio-Canada", arial, helvetica, sans-serif;
$font-family-titre: "Radio-Canada", arial, helvetica, sans-serif;

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.125); //18px
$font-size-sm: ($font-size-base * 0.875); //14px;

$font-weight-lighter: 400;
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$line-height-base: 1.35;

$h1-font-size: $font-size-base * 2.75; //44px (font-weight :normal)
$h2-font-size: $font-size-base * 1.875; //30px (font-weight :500)
$h3-font-size: $font-size-base * 1.5; //24px (font-weight:normal)
$h4-font-size: $font-size-base * 1.3215; //21px
$h5-font-size: $font-size-base * 1.125; //18px
$h6-font-size: $font-size-base * 1.125; //18px

//$headings-margin-bottom: $spacer / 2;
$headings-margin-bottom: 0.5rem;
$headings-font-family: $font-family-titre;
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.1;

$text-muted: rgba($white, 0.7); //white 70% opacity
$small-font-size: 87.5%;

$lead-font-size: $font-size-lg;
$lead-font-weight: 300 !default;

// Buttons + Forms
$input-btn-padding-x: 0.75rem;
$input-btn-padding-y: 0.3125rem;

$input-btn-focus-width: 0.125rem;
$input-btn-focus-color: $focus-color;

$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $blue,
  0 0 0 calc(#{$input-btn-focus-width} * 2) $input-btn-focus-color;

$input-btn-padding-y-lg: 0.3125rem;
$input-btn-padding-x-lg: 1.25rem;

$input-btn-border-width: 2px;

// Buttons
$btn-font-weight: $font-weight-bold;

//Small : 28px
$btn-font-size-sm: $font-size-sm; //14px
$btn-line-height-sm: 1.25;
$btn-padding-y-sm: calc(
  (0.875rem - #{$input-btn-border-width}) -
    (#{$btn-font-size-sm} * #{$btn-line-height-sm}) / 2
); //2.9px
$btn-padding-x-sm: 0.625rem; //10px

//MD : 38px
$btn-font-size: $font-size-base;
$btn-line-height: 1.25;
$btn-padding-y: calc(
  (1.1875rem - #{$input-btn-border-width}) -
    (#{$btn-font-size} * #{$btn-line-height}) / 2
); //6.6px
$btn-padding-x-sm: 0.8rem;

//Large : 48px
$btn-font-size-lg: $font-size-lg;
$btn-line-height-lg: 1.25;
$btn-padding-y-lg: calc(
  (1.5rem - #{$input-btn-border-width}) -
    (#{$btn-font-size-lg} * #{$btn-line-height-lg}) / 2
); //9.6px
$btn-padding-x-lg: 1.25rem;

// Forms

$label-margin-bottom: 0.5rem;

$input-bg: $white;
$input-disabled-bg: $gray-400;

$input-color: $black;
$input-font-weight: 400;

$input-border-color: $blue;
$input-border-width: 0.125rem; //2px
$input-height-border: $input-border-width * 2;
$input-border-radius: 0.25rem;
$input-border-radius-lg: 0.25rem;
$input-border-radius-sm: 0.25rem;

$input-focus-color: $black;
$input-focus-border-color: $blue;

//Total height: 28px (1.75rem)
$input-padding-y-sm: 0.14rem;
$input-padding-x-sm: 0.75rem; //12px
$input-font-size-sm: 0.9375rem; //15px
$input-line-height-sm: 1.3;
//$input-height-inner-sm:                 ($input-font-size-sm * $input-line-height-sm) + ($input-padding-y-sm * 2) !default;
//$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-sm: 1.75rem; //28px;

//Total height: 38px (2.375rem)
$input-padding-y: 0.41rem;
$input-padding-x: 0.75rem; //12px
$input-font-size: 1rem;
$input-line-height: 1.3;
//$input-height-inner:                    ($input-font-size * $input-line-height) + ($input-padding-y * 2) !default;
//$input-height:                          calc(#{$input-height-inner} + #{$input-height-border});
$input-height: 2.375rem; //38px;

//Total height: 48px (3rem)
$input-padding-y-lg: 0.8rem;
$input-padding-x-lg: 0.75rem; //12px
$input-font-size-lg: 1rem;
// $input-line-height-lg:                  1.3;
// $input-height-inner-lg:                 ($input-font-size-lg * $input-line-height-lg) + ($input-padding-y-lg * 2) !default;
$input-height-lg: 3rem;

$form-group-margin-bottom: 1.5rem !default;

$custom-control-indicator-border-color: $component-active-bg;
$custom-control-indicator-focus-border-color: $component-active-bg;
$custom-control-indicator-disabled-bg: #888;
$custom-control-label-disabled-color: #888;

//Switch
$custom-switch-width: 2.125rem;
$custom-switch-indicator-border-radius: 0.75rem;
$custom-switch-indicator-size: 0.75rem;

//Select
$custom-select-padding-x: 0.5rem;
$custom-select-bg-size: 14px 12px;
$custom-select-indicator-color: $dark;
$custom-select-indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23000000' d='M.67,5.43,7,11.78a1.4,1.4,0,0,0,2,0l6.35-6.35a1.39,1.39,0,0,0-2-2L8,8.84,2.63,3.47a1.39,1.39,0,0,0-2,2Z'/%3e%3c/svg%3e");
$custom-select-background: $custom-select-indicator no-repeat right
  $custom-select-padding-x center / $custom-select-bg-size;

// Form validation

$form-feedback-margin-top: 0.25rem;
$form-feedback-font-size: 0.9375rem; //15px
$form-feedback-valid-color: $input-border-color; //theme-color("success") !default;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $success;
//$form-feedback-icon-valid:          str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
//$form-feedback-icon-invalid:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") !default;

// Dropdowns
//
// Dropdown menu container and contents.

// $dropdown-min-width:                10rem !default;
// $dropdown-padding-y:                .5rem !default;
// $dropdown-spacer:                   .125rem !default;
$dropdown-bg: $white;
$dropdown-border-color: $white;
// $dropdown-border-radius:            $border-radius !default;
// $dropdown-border-width:             $border-width !default;
//$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default;
$dropdown-divider-bg: $gray-400;

//$dropdown-box-shadow:                 0px 3px 6px rgba(0, 0, 0, 0.3); //Ne fonctionne pas

// $dropdown-link-color:               $gray-900 !default;
// $dropdown-link-hover-color:         darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-200;
// $dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg: $gray-200; //$component-active-bg !default;
// $dropdown-link-disabled-color:      $gray-600 !default;
// $dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x: 0.75rem !default;

// Pagination
$pagination-color: $black;
$pagination-hover-color: $black;
$pagination-active-color: $white;
$pagination-active-bg: $red;
$pagination-active-border-color: $red;

// Cards
$card-spacer-y: 1.25rem; //.75rem !default;
$card-spacer-x: 1.25rem; //1.25rem !default;
$card-border-width: 2px;
$card-border-radius: 0.25rem; // 4px;
$card-border-color: rgba($white, 0.25);
$card-cap-bg: #fff;
$card-bg: #fff;

// Badges
$badge-font-size: 0.75rem !default;
$badge-font-weight: normal !default;
$badge-padding-y: 0.375rem !default;
$badge-padding-x: 0.625rem !default;
$badge-border-radius: $border-radius;

$badge-pill-padding-x: 0.6rem !default;
$badge-pill-border-radius: 10rem !default;

//Modals
$modal-inner-padding: 1.25rem; //20px;
$modal-title-line-height: 1.25;
$modal-content-border-width: 0;
$modal-header-border-color: $gray-300;
$modal-header-border-width: 2px;
$modal-footer-border-width: 2px;
$modal-header-padding: 1.25rem 1.25rem 1rem; //20px;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 575px; //500px !default
$modal-sm: 425px; //300px !default

// Progress bars
//$progress-bar-bg: $secondary;

// Thumbnails
$thumbnail-border-radius: $border-radius;
$thumbnail-padding: 0;

// Breadcrumb
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23FFFFFF' d='M5.88.68a1.4,1.4,0,1,0-2,1.95L9.24,8,3.85,13.35a1.38,1.38,0,0,0,0,2,1.4,1.4,0,0,0,2,0L12.17,9a1.43,1.43,0,0,0,.41-1,1.41,1.41,0,0,0-.4-1L5.85.69Z'/%3e%3c/svg%3e");
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;
$breadcrumb-border-radius: 0;

// Navs
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $more-muted;

// $nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width: 2px;
// $nav-tabs-border-radius:            $border-radius !default;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
// $nav-tabs-link-active-color:        $gray-700 !default;
// $nav-tabs-link-active-bg:           $body-bg !default;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

// $nav-pills-border-radius:           $border-radius !default;
// $nav-pills-link-active-color:       $component-active-color !default;
// $nav-pills-link-active-bg:          $component-active-bg !default;

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: $spacer / 2 !default;

// Navbar

$navbar-padding-y: 0.35rem;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 0.5rem;

//$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: 0;

$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$navbar-dark-toggler-border-color: transparent;

$navbar-light-color: $black;
$navbar-light-hover-color: $black;
//$navbar-light-active-color:         $primary;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
) !default;
$navbar-light-toggler-border-color: transparent;

//$navbar-light-brand-color:                $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
// $navbar-dark-brand-color:                 $navbar-dark-active-color !default;
// $navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;

// Toasts
$toast-padding-x: 1.25rem;
$toast-padding-y: 1.25rem;
$toast-font-size: $font-size-base;
$toast-background-color: $white;
$toast-border-width: 0;
$toast-border-color: rgba(0, 0, 0, 0.1);
$toast-border-radius: 0.3125rem;

//Alert
$alert-padding-y: 1.25rem;
$alert-padding-x: 1rem;

// Tooltip
$tooltip-font-size: $font-size-base;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $blue-darker;
$tooltip-border-radius: 0px;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.25rem;
$tooltip-padding-x: 1rem;
$tooltip-margin: 0.5rem;

$tooltip-arrow-width: 1.8325rem;
$tooltip-arrow-height: 0.625rem;
$tooltip-arrow-color: $tooltip-bg;

/*Table*/
$table-cell-padding: 1rem;
$table-head-color: $black;
