@import "@/libscss/sites/webtelly/variables/_bootstrap-custom"; @import "@/libscss/sites/webtelly/variables/_globals";

.rc-notification {
  background-color: var(--white);

  &-msg {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--black);
  }
  .rc-btn-close {
    position: absolute;
    top: 2px;
    right: 2px;
    color: var(--black);
    background-color: var(--white);
    &:hover {
      color: var(--gray);
    }
  }
}
