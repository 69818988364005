// ------------------------------------------
// PROPERTIES GENERAL
//-------------------------------------------
:root {
  //Color
  --text-muted: #{$text-muted};

  --rc-colorA: #{$red-wine};
  --rc-colorB: #{$coral};
  --rc-colorC: #{$pink};
  --rc-colorD: #{$purple};
  --rc-colorE: #{$indigo};
  --rc-colorF: #{$navy};
  --rc-colorG: #{$blue-duck};
  --rc-colorH: #{$turquoise};
  --rc-colorI: #{$green-salad};
  --rc-colorJ: #{$green-forest};

  --rc-color-event: #{$blue-duck};

  //Height combined header and footer
  --rc-height-headerFooter: 163px;
  @include media-breakpoint-up(xxxl) {
    --rc-height-headerFooter: 179px;
  }
  //Pixel by minute
  --rc-px-min: 4px;

  //Duration timeslot (min)
  --rc-min: 60;

  //Duration for base (min)
  --rc-base-min: 60;

  //Playing now
  --rc-playing-now-pourcent: 0%;

  //Border base
  --rc-border-base: 1px solid rgba(255, 255, 255, 0.1);

  //Border for background blue
  --rc-border-bg-blue: 1px solid rgba(255, 255, 255, 0.5);

  //Border for background dark
  --rc-border-bg-dark: 1px solid rgba(255, 255, 255, 0.25);

  //Border for background light or white
  --rc-border-bg-light: 1px solid #{$gray-300};

  //Border gray (for modal)
  --rc-border-gray-600: 1px solid #{$gray-600};

  //Border for bloc separation
  --rc-border-separator: 4px solid rgba(0, 0, 0, 0.2);

  //Border radius for container
  --rc-border-radius-wrapper: 10px;
}

// ------------------------------------------
// PROPERTIES COMPONENT
//-------------------------------------------

// ------------------------------------------
// Filters
//-------------------------------------------
.rc-list-filters {
  --rc-btn-clear-color: #{$white};
}
.rc-dropdown-filters {
  --rc-menu-border-color: #{$gray-300};
}
// ------------------------------------------
// Toolbar (list actions)
//-------------------------------------------
ul.rc-toolbar {
  --rc-toolbar-li-border: var(--rc-border-bg-dark);
}

// ------------------------------------------
// Rating
//-------------------------------------------
.rc-rating {
  --rc-rating-border: 2px solid rgba(255, 255, 255, 0.25);
  --rc-rating-bg: #{$dark};
  --rc-rating-star-empty: rgba(255, 255, 255, 0.25);
  --rc-rating-star-full: #{$primary};
  --rc-rating-star-empty-disabled: rgba(255, 255, 255, 0.25);
}
