// ------------------------------------------
// HEADER
//-------------------------------------------
.rc-main-navigation {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  //Nav Left
  .navbar-brand {
    img {
      width: 10rem;
    }
  }
  .rc-toggle-nav {
    flex-basis: 33.33%;
  }
  .navbar-toggler {
    margin-right: 0 !important;
    &:hover {
      color: $navbar-dark-hover-color;
    }
  }
  //Nav Right
  .rc-nav-right {
    flex-basis: auto;

    .navbar-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .rc-btn-sign-in {
      margin-left: 1.5rem;
    }
    .dropdown.rc-menu-account {
      margin-left: 1.5rem;
      > a {
        padding: 0;
        + ul {
          margin-right: 0.5rem;
        }
      }
      .rc-btn-dropdown-my-account svg {
        font-size: 300% !important;
      }
      .dropdown-menu {
        position: absolute;
        width: toRem(280px);
      }
      .dropdown-item {
        padding-right: 1rem;
        padding-left: 1rem;
      }
      //Team switcher
      .rc-team-switcher {
        margin-right: 1rem;
        margin-left: 1rem;
        padding-bottom: 0.25rem;

        &--header {
          padding-right: 0;
          padding-left: 0;
          padding-top: 0.1rem;
          font-weight: $font-weight-bold;
          font-size: $font-size-lg;
          color: var(--black);
          border-bottom: var(--rc-border-bg-light);
        }
        ul {
          li {
            border-bottom: var(--rc-border-bg-light);
            padding-top: 2.5px;
            padding-bottom: 2.5px;

            &:last-child {
              border-bottom: 0;
            }
            a,
            .rc-team-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-left: var(--rc-border-bg-light);
              border-left-width: 4px;
              padding-left: 0.75rem;
              padding-right: 4px;

              &.active,
              &:active {
                border-left-color: var(--active);
                background-color: rgba(244, 206, 46, 0.17) !important;
              }
              .rc-team-name {
                @extend .rc-ellipsis-simple;
                padding-right: 0.5rem;
                flex-basis: auto;
                font-size: $font-size-base;
              }
              .rc-tag-signedIn {
                display: inline-block;
                background-color: var(--active);
                border-radius: 4px;
                padding: 2px 0.75rem;
                flex-shrink: 0;
                font-size: $font-size-sm;
                color: $gray-800;
              }
              &.rc-link-all-teams {
                justify-content: center;
                padding-top: 0.4rem;
                border-left: 0;

                color: $gray-600;
                font-size: $font-size-sm;
                &:hover {
                  background-color: transparent;
                  text-decoration: underline !important;
                }
                &.active,
                &:active {
                  background-color: transparent !important;
                }
              }
            }
          }
        }
      }
    }
    .rc-btn-lang {
      font-size: $font-size-lg;
      font-weight: $font-weight-normal;

      &:hover {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
}
@include media-breakpoint-up(xxxl) {
  .rc-main-navigation {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

//Breadcrumb
.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto 1.5rem 0;
  width: auto;
}
.breadcrumb-item {
  font-size: $font-size-base;
  flex-shrink: 0;

  + .breadcrumb-item::before {
    width: 1.25rem;
    height: 1px;
    margin: 0.3625rem 0.25rem 0.25rem 0.4rem;
  }

  &.active {
    font-weight: $font-weight-bold;
    flex-shrink: 1;
    width: 100%;
    max-width: toRem(800px);
    @extend .rc-ellipsis-simple;
  }
  a:hover {
    color: $navbar-dark-hover-color;
  }
}
@include media-breakpoint-up(lg) {
  .breadcrumb {
    margin: 0 auto 0 4rem;
  }
  .breadcrumb-item {
    font-size: $font-size-lg;

    + .breadcrumb-item::before {
      width: 1.55rem;
      margin: 0.3rem 0.85rem 0.25rem 1rem;
    }

    &.active {
      max-width: toRem(500px);
    }
  }
}
@include media-breakpoint-up(xxl) {
  .breadcrumb-item {
    &.active {
      max-width: toRem(650px);
    }
  }
}
@include media-breakpoint-up(xxxl) {
  .breadcrumb-item {
    &.active {
      max-width: toRem(900px);
    }
  }
}

//Sidebar
.rc-nav-sidebar {
  width: 100%;
  max-width: 360px;
  overflow-y: auto;
  bottom: 0;

  .rc-header-sidebar {
    padding: 1.5rem 2rem 2rem 2rem;

    .btn {
      width: auto;
      height: auto;
      padding: 0;
      margin-left: toRem(-8px);
      &:hover {
        color: $navbar-dark-hover-color;
      }
    }
  }

  .b-sidebar-body {
    overflow: visible;

    .navbar-nav {
      padding-top: 0.5rem;
      padding-bottom: 0.75rem;
      border-top: var(--rc-border-base);
      border-top-color: rgba(255, 255, 255, 0.1);

      &.rc-navbar-dropdown {
        padding-top: 0;
        padding-bottom: 0;
      }
      &.rc-navbar-home {
        border-top: 0;
        padding-top: 0;
        padding-bottom: 0.75rem;
        .nav-item {
          //à uniformiser
          font-size: toRem(20px);
          .rc-link-icon--text svg {
            margin-right: 0.8rem;
            margin-left: 0;
          }
        }
      }
    }
    .navbar-text {
      font-size: $h5-font-size;
      line-height: 1.25;
      font-weight: $font-weight-base;
      color: var(--text-muted);
      padding-right: 1.5rem;
      padding-left: 2rem;
    }
    .nav-item {
      .nav-link {
        padding: 0.5rem 1.5rem 0.5rem 1.6875rem;
        border-left: 5px solid transparent;
        font-weight: $font-weight-bold;

        &.router-link-exact-active {
          background-color: rgba($darker, 0.4);
          border-left: 5px solid var(--active);
        }
        &:hover,
        &:focus,
        &.active,
        &:active {
          text-decoration: none !important;
          color: var(--white);
          background-color: var(--blue);
        }
        .rc-download {
          margin-top: toRem(-6px);
        }
      }

      .rc-link-icon--text {
        display: flex;
        align-items: center;
        svg {
          margin-right: 0.8rem;
          margin-left: 0.2rem;
        }
      }
    }
    // .nav-item.dropdown {
    //   padding: 0;

    //   &:not(:first-child) {
    //     border: var(--rc-border-base);
    //     border-color: rgba(255, 255, 255, 0.1);
    //     border-right: 0;
    //     border-left: 0;
    //   }

    //   .dropdown-toggle {
    //     padding: 1rem 1.5rem 1rem 1.6875rem;
    //     font-size: $h5-font-size;
    //     font-weight: $font-weight-base;
    //     color: var(--text-muted);
    //     white-space: normal;
    //   }
    //   .dropdown-menu {
    //     padding-top: 0;
    //     padding-bottom: 0.9rem;
    //     box-shadow: none !important;
    //   }
    //   .dropdown-item {
    //     padding: 0.5rem 1.5rem 0.5rem 1.6875rem;
    //     font-weight: $font-weight-bold;
    //     white-space: normal;
    //     color: var(--white);
    //     background-color: transparent;
    //     border-left: 5px solid transparent;

    //     &.router-link-active {
    //       background-color: rgba($darker, 0.4);
    //       border-left: 5px solid var(--active);
    //     }

    //     &:hover,
    //     &:focus,
    //     &.active,
    //     &:active {
    //       color: var(--white) !important;
    //       background-color: var(--blue) !important;
    //     }
    //   }
    //}

    .accordion {
      button[role="tab"] {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        max-width: 100%;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        padding: 0.5rem 1.5rem 0.5rem 1.6875rem;

        border: 0;
        border-radius: 0;
        border-left: 5px solid transparent;

        font-size: $h5-font-size;
        font-weight: $font-weight-base;
        color: var(--text-muted);
        white-space: normal;

        &:focus,
        &:hover {
          background-color: var(--blue);
        }
      }

      > div {
        border-top: var(--rc-border-base);

        // &:last-child {
        //   border-bottom: var(--rc-border-base);
        // }
      }

      .navbar-nav {
        padding-top: 5px;
        padding-bottom: 0.75rem;
        border-top: 0;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .rc-nav-sidebar {
    .rc-header-sidebar {
      .rc-logo-wrapper {
        margin-top: 2rem;
      }
    }
  }
}
