@import "@/libscss/sites/webtelly/variables/_bootstrap-custom"; @import "@/libscss/sites/webtelly/variables/_globals";

.rc-alert-cookie {
  position: fixed !important;
  bottom: 0;
  margin: 1.25rem !important;
  max-width: 340px;
  z-index: 2000;
  color: black !important;
  background-color: white !important;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  outline: 0;
  padding: 1.25rem !important;

  .rc-btn-accept {
    margin-top: 1rem;
    width: 100%;
  }
}
