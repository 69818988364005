.muted {
  opacity: 0.5;
  pointer-events: none;
}

.underline {
  text-decoration: underline;
}

//Variation du btn-link sans padding pour btn qui ressemble à un lien
.rc-btn-link {
  padding: 0;
  text-decoration: underline !important;
  &:hover {
    color: inherit !important;
    text-decoration: underline !important;
  }
}

.rc-ellipsis {
  white-space: nowrap;
  position: relative;
  left: 0px;
  transform: translateX(0%);
  transition: all 20s ease;
  &:not(:hover) {
    text-overflow: ellipsis;
    transition: all 0.5s ease;
    overflow: hidden;
  }
  &-active:hover {
    transform: translateX(-150%);
    text-overflow: unset;
    overflow: unset;
  }
}

.rc-ellipsis-simple {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rc-disclaimer {
  font-size: $small-font-size;
  color: $text-muted;
  font-style: italic;
}

.rc-divider-or {
  --margin-bottom: 2rem;
  --margin-top: 2rem;
  //--line-color-rgb: 136, 136, 136; // #888;
  --line-color: var(--light);
  --text-color: #000;
  --line-height: 2px;

  display: flex;
  margin-top: var(--margin-top);
  margin-bottom: var(--margin-bottom);

  &--line {
    flex-grow: 1;
    flex-shrink: 1;
    //background-color: rgba(var(--line-color-rgb), 0.6);
    background-color: var(--line-color);
    height: var(--line-height);
    position: relative;
    top: 0.45rem;
  }
  &--text {
    text-transform: capitalize;
    color: var(--text-color);
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 0.8;
    margin: 0 1.125rem;
  }
}

//Lists
ul.rc-list-bullet {
  margin-bottom: 1.3rem;
  li {
    list-style-type: disc;
    margin-bottom: 0.3rem;
    margin-left: 1.75rem;
    font-size: $font-size-base;
  }
}

//Skeleton  (Use "placeholder" rather then skeleton with Bootstrap 5)
.rc-skeleton {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.12);
  cursor: wait;
  -webkit-mask-image: radial-gradient(#fff, #000);
  mask-image: radial-gradient(#fff, #000);
  opacity: 0.4;

  &:before {
    content: " ";
  }

  &-text {
    height: 1rem;
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
  }
  &-img {
    height: 100%;
    width: 100%;

    &:before {
      content: " ";
    }
  }
  &-input {
    opacity: 1;
    &.form-control {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

@keyframes rc-skeleton-animate-wave {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes rc-skeleton-animate-fade {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}

.rc-skeleton-animate-wave:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );
  animation: rc-skeleton-animate-wave 1.75s linear infinite;
}

.rc-skeleton-animate-fade {
  animation: rc-skeleton-animate-fade 0.875s ease-in-out infinite alternate;
}

@media (prefers-reduced-motion: reduce) {
  .rc-skeleton-animate-fade {
    animation: none;
  }
  .rc-skeleton-animate-wave:after {
    animation: none;
    background: none;
  }
}
//For Bootstrap 4 classes are : "embed-responsive embed-responsibe-16by9"
//For Bootstrap 5 classes are : "ratio ratio-16x9"
.embed-responsive {
  > div:first-of-type,
  img {
    position: absolute;
    top: 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.rc-object-fit-contain {
  object-fit: contain;
}
//Avatar
.rc-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &-with-badge {
    position: relative;
    overflow: visible;
  }

  &-badge {
    position: absolute;
    top: -2px;
    right: -2px;
    height: 0.75rem;
    width: 0.75rem;
  }
}
