// ------------------------------------------
// FOOTER
//-------------------------------------------
footer.rc-footer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: $font-size-sm;
  color: $text-muted;

  a {
    color: $text-muted;
  }
}

@include media-breakpoint-up(lg) {
  footer.rc-footer {
    text-align: left;

    .row {
      flex-direction: row-reverse;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    ul {
      @include list-separator-border($blue-light);
      justify-content: flex-end;
      margin-bottom: 0;
      li {
        margin-bottom: 0;
      }
    }
  }
}
