// ------------------------------------------
// TABS
//-------------------------------------------
// Customize tabs (voir pour mettre dans variables bootstrap ce qui est possible)
.nav-tabs {
  flex-wrap: nowrap;
  border-bottom: var(--rc-border-base);
  border-bottom-width: 2px;
  font-size: $h4-font-size;

  li.nav-item {
    margin-right: toRem(30px);

    &:last-child {
      margin-right: 0;
    }
  }
  a.nav-link {
    padding: 0.35rem 0 0.3rem;
    color: rgba(255, 255, 255, 0.5);
    border: 0;
    border-bottom: 4px solid transparent;
    text-decoration: none !important;

    &:hover {
      border-color: rgba(255, 255, 255, 0.5);
    }
    &.active {
      color: var(--white);
      border-color: var(--active);
      background-color: transparent;
    }
  }
}

.tab-content {
  a:not(.dropdown-item) {
    text-decoration: underline;
  }
}
@include media-breakpoint-up(md) {
  .nav-tabs {
    font-size: $h3-font-size;
  }
}
// TABS (detail page : overview, metadatas)
.rc-date-details .row > .col:first-child {
  max-width: toRem(120px);
  color: var(--text-muted);
}

.rc-l-schedulable {
  .rc-date-details .row > .col:first-child {
    //max-width: toRem(170px);
    max-width: 8rem;
  }
}

// TABS - STEPPER
.rc-tabs-stepper {
  &--nav {
    padding: 1rem 1.25rem;
    border-bottom: var(--rc-modal-border);
    border-width: 2px;

    ul {
      display: flex;
      justify-content: space-between;

      li {
        position: relative;
        display: flex;
        align-items: center;
        flex-grow: 1;

        &:not(:first-child)::before {
          position: relative;
          flex: 1;
          height: 2px;
          margin: -1.25rem 0.5rem 0 0;
          content: "";
          background-color: rgba(0, 0, 0, 0.1);
        }
        &:not(:last-child)::after {
          position: relative;
          flex: 1;
          height: 2px;
          margin: -1.25rem 0 0 0.5rem;
          content: "";
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    //If only 2 tabs
    &.rc-tabs-nb-2 {
      ul {
        justify-content: center;
        li {
          flex-basis: 30%;
          flex-grow: 0;
        }
      }
    }

    a[role="tab"] {
      color: black;
      padding-right: 0;
      padding-left: 0;

      .rc-step--number {
        display: block;
        height: 1.325rem;
        width: 1.325rem;
        border-radius: 50%;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0.5rem;
        font-size: $font-size-sm;
        font-weight: $font-weight-normal;
        text-align: center;
        line-height: 1.4;
        svg.rc-check {
          margin-top: 1px;
        }
      }
      .rc-step--title {
        display: block;
        text-transform: uppercase;
        font-size: toRem(12px);
        font-weight: $font-weight-bold;
      }

      //Step Completed
      &.rc-step--completed {
        .rc-step--number {
          background-color: var(--success);
          color: var(--white);
        }
        &.active {
          .rc-step--number {
            outline: 2px solid var(--success);
            outline-offset: 2px;
          }
        }
      }

      //Step Incomplete
      &.rc-step--incomplete {
        .rc-step--number {
          background-color: var(--blue);
          color: var(--white);
        }
        &.active {
          .rc-step--number {
            outline: 2px solid var(--blue);
            outline-offset: 2px;
          }
        }

        &.disabled {
          .rc-step--title {
            color: var(--gray);
          }
          .rc-step--number {
            background-color: $gray-400;
            color: var(--white);
          }
        }
      }
    }
  }

  .rc-step-body {
    min-height: toRem(525px);
    padding: 1rem 1.25rem;

    .rc-summary {
      padding-top: 1.25rem;
      .h4 {
        font-weight: 500;
        margin-bottom: 0.2rem;
      }
      .h5 {
        margin-bottom: 2px;
      }
      .rc-container-overflow {
        padding: 0 0 1rem 0;
        .row {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }
  }

  .rc-step-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 1rem 1.25rem;
    margin-top: 0.25rem;
    border-top: var(--rc-modal-border);
    border-width: 2px;
  }

  @include media-breakpoint-up(sm) {
    ul {
      li {
        &:not(:first-child)::before {
          margin: 0.25rem 0.5rem 0 0;
        }
        &:not(:last-child)::after {
          margin: 0.25rem 0 0 0.5rem;
        }
      }
    }
    a[role="tab"] {
      display: flex;
      align-items: center;
      .rc-step--number {
        display: unset;
        margin: unset;
        margin-right: 0.5rem;
        font-size: $font-size-sm;
      }
      .rc-step--title {
        display: unset;
        font-size: $font-size-sm;
      }
    }
  }
}
