// ------------------------------------------
// LAYOUT HOME (List channels)
//-------------------------------------------
.rc-channels {
  > li {
    text-align: center;
  }
  ul.rc-list-border {
    justify-content: center;

    li {
      margin-right: 1rem;
      padding-right: 0.75rem;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }
      a {
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        &:hover {
          color: $navbar-dark-hover-color;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxxl) {
  .rc-channels {
    > li {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
}
