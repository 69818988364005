// ------------------------------------------
// List, Grid, Card Unit (Distribution, Webtelly : list unit and grid unit)
//-------------------------------------------
.rc-list-unit,
.rc-grid-unit,
.rc-screener-unit,
.rc-row-unit {
  .rc-thumbnail-wrapper {
    // position: relative;
    // display: block;
    // background: $black;
    // height: 0;
    // overflow: hidden;
    // padding-bottom: 56.25%;

    img {
      // width: 100%;
      // max-width: 100%;
      // height: auto;
      transition: 0.2s filter linear;
    }
  }
  .rc-duration,
  .rc-nb-items {
    position: absolute;
    bottom: toRem(10px);
    right: toRem(10px);
    padding: 0.0625rem 0.4375rem;
    border-radius: 3px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
  .rc-stack-effect {
    margin-top: 10px;
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      border-top: 2px solid $light;
      border-radius: 2px;
    }
    &::before {
      width: 94%;
      left: 3%;
      top: -5px;
    }
    &::after {
      width: 88%;
      left: 6%;
      top: -10px;
    }

    + .rc-ribbon {
      top: 1.25rem;
    }
  }

  .rc-sur-title {
    color: $primary;
  }

  .rc-list-border {
    color: $text-muted;
    li {
      flex-shrink: unset;
      &:first-letter {
        text-transform: uppercase;
      }

      .rc-cc {
        margin-left: 3px;
      }
    }
  }

  .rc-tag-end-rights {
    display: flex;
    align-items: center;
    img {
      width: toRem(20px);
      margin-right: 0.5rem;
    }
  }

  &:hover .rc-thumbnail-wrapper img {
    filter: brightness(115%);
  }
}
// ------------------------------------------
// Specific to Screener Unit
//-------------------------------------------
.rc-screener-unit {
  .h4 {
    margin-bottom: 0.2rem;
  }
  .rc-duration-wrapper {
    .rc-duration {
      position: unset;
    }
  }
}
// ------------------------------------------
// Specific to List Unit
//-------------------------------------------
.rc-list-unit {
  position: relative;
  div[class^="col"] {
    position: static;
  }

  header {
    margin-top: 0.3rem;
    max-width: toRem(650px);

    .rc-sur-title {
      margin-bottom: 0.2rem;
    }

    h3 {
      margin-bottom: 0.2rem;
    }
  }
  &--stacked {
    header {
      margin-top: 1rem;
    }
  }
  .rc-summary {
    display: none;
  }
}
@include media-breakpoint-up(md) {
  .rc-list-unit {
    .rc-summary {
      margin-top: 0.3rem;
      @include line-clamp(2, black);

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .rc-list-unit {
    .rc-summary {
      -webkit-line-clamp: 3;
    }
  }
}

@media (min-width: 1820px) {
  .rc-list-unit {
    .rc-summary {
      -webkit-line-clamp: 4;
    }
  }
}
// ------------------------------------------
// Specific to Row Unit (look like table) (used for list import and list playlist Webtelly)
//-------------------------------------------
.rc-row-unit {
  position: relative;

  &--clickable {
    > div {
      position: static;
    }
    a.stretched-link {
      display: inline-block;
    }
  }
  > div:last-child {
    flex-grow: 1;
    max-width: unset;
  }
}
//(for stacked effect)
@include media-breakpoint-up(lg) {
  .d-lg-block {
    &.rc-col-thumbnail--stacked {
      padding-top: toRem(12px);
      .rc-stack-effect {
        margin-top: toRem(-2px);
      }
      ~ div {
        padding-top: toRem(12px);
      }
    }
  }
}
// ------------------------------------------
// Specific to Grid Unit
//-------------------------------------------
.rc-grid-unit {
  position: relative;
  margin-bottom: toRem(30px);
  width: 100%;

  .rc-ribbon {
    left: toRem(-10px);
  }

  header {
    margin-top: 0.5rem;

    .rc-sur-title {
      margin-bottom: 0.1rem;
    }

    h3 {
      margin-bottom: 0.2rem;
    }
  }
}
