// ------------------------------------------
// FONTS
//-------------------------------------------
@font-face {
  font-family: "Radio-Canada";
  src: url('#{$base-font-path}radiocanada/radio-canada-regular.woff2') format("woff2"),
      url('#{$base-font-path}radiocanada/radio-canada-regular.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Radio-Canada";
  src: url('#{$base-font-path}radiocanada/radio-canada-medium.woff2') format("woff2"),
      url('#{$base-font-path}radiocanada/radio-canada-medium.woff') format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Radio-Canada";
  src: url('#{$base-font-path}radiocanada/radio-canada-bold.woff2') format("woff2"),
      url('#{$base-font-path}radiocanada/radio-canada-bold.woff') format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}