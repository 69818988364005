// ------------------------------------------
// Toolbar (list actions)
//-------------------------------------------
ul.rc-toolbar {
  --rc-toolbar-li-border: 1px solid #{$light};

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 0;

  .btn {
    display: block !important;
    height: auto;
    font-size: $font-size-sm;
    text-align: center;
    padding-right: 0.1rem;
    padding-left: 0.1rem;
    font-weight: $font-weight-base;

    svg {
      display: block !important;
      font-size: 150%;
      margin: 0 auto 0.5rem;
    }
  }
}

//Used in Curio
.rc-unlock-action {
  color: $secondary;
  margin: 0.5rem 0rem 0.5rem 0;
}

@include media-breakpoint-up(sm) {
  ul.rc-toolbar {
    justify-content: flex-start;
    margin-left: -0.5rem;

    li {
      flex-basis: unset;

      &::after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 1.5rem;
        border-right: var(--rc-toolbar-li-border);
        transform: translate(0px, 0.5rem);
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        speak: none;
      }
      &:last-child::after {
        display: none;
      }
    }
    .btn {
      display: inline-block !important;
      height: unset;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      text-align: left;
      font-size: $font-size-base;

      svg {
        display: inline-block !important;
        margin: 0 0.5rem 0 0;
        font-size: 125%;
      }
      svg.rc-rename {
        margin-right: 0.6rem;
        margin-left: 0.1rem;
      }
      svg.rc-playlist-add {
        margin-right: 0.7rem;
        margin-left: 0.1rem;
      }
    }
  }

  //Used in Curio
  .rc-unlock-action {
    color: $secondary;
    margin: 0;
    margin-left: 0.25rem;

    padding-left: 1rem;
    border-left: 1px solid $light;
  }
}
