// -----------------------------
// Boxes
// -----------------------------

// Box Sizing
@mixin box-sizing($sizing: border-box) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  box-sizing: $sizing;
}

// Box Shadow
@mixin box-shadow($inset: false, $xy: 1px 1px 2px, $color: #000) {
  @if $inset {
    -webkit-box-shadow: inset $xy $color;
    -moz-box-shadow: inset $xy $color;
    box-shadow: inset $xy $color;
  } @else {
    -webkit-box-shadow: $xy $color;
    -moz-box-shadow: $xy $color;
    box-shadow: $xy $color;
  }
}

// -----------------------------
// CSS3 Animations
// -----------------------------

// Simple Transition
@mixin simple-transition($trans: all, $duration: 0.4s, $ease: ease) {
  transition: $trans + " " + $duration + " " + $ease;
  -moz-transition: $trans + " " + $duration + " " + $ease;
  -webkit-transition: $trans + " " + $duration + " " + $ease;
  -o-transition: $trans + " " + $duration + " " + $ease;
}

// Double Transitions
// example: @include double-transition(background, 1s, ease-in-out, 0, opacity, .1s, ease-in-out, 0);

@mixin double-transition(
  $property1,
  $duration1,
  $timing-function1,
  $delay1,
  $property2,
  $duration2,
  $timing-function2,
  $delay2
) {
  -webkit-transition: $property1 $duration1 $timing-function1 $delay1,
    $property2 $duration2 $timing-function2 $delay2;
  -moz-transition: $property1 $duration1 $timing-function1 $delay1,
    $property2 $duration2 $timing-function2 $delay2;
  -o-transition: $property1 $duration1 $timing-function1 $delay1,
    $property2 $duration2 $timing-function2 $delay2;
  transition: $property1 $duration1 $timing-function1 $delay1,
    $property2 $duration2 $timing-function2 $delay2;
}

@mixin transition($transition) {
  -webkit-transition: #{$transition};
  transition: #{$transition};
}

// Transformations
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  transform: $transform;
}
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  transform-origin: $origin;
}
@mixin transform-style($style) {
  -webkit-transform-style: $style;
  -moz-transform-style: $style;
  transform-style: $style;
}

// -----------------------------
// CSS Triangles
// -----------------------------
@mixin triangle($direction, $color, $size) {
  @if $direction == "left" {
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-top: $size solid transparent;
  } @else if $direction == "right" {
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-top: $size solid transparent;
  } @else if $direction == "up" {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  } @else if $direction == "down" {
    border-right: $size solid transparent;
    border-left: $size solid transparent;
    border-top: $size solid $color;
  }
  height: 0;
  width: 0;
}

// -----------------------------------------
// Content Columns
// -----------------------------------------
@mixin content-columns($count: 3, $gap: 10) {
  -webkit-column-count: $count; /* Chrome, Safari, Opera */
  -moz-column-count: $count; /* Firefox */
  column-count: $count;
  -webkit-column-gap: $gap + px; /* Chrome, Safari, Opera */
  -moz-column-gap: $gap + px; /* Firefox */
  column-gap: $gap + px;
}

// -----------------------------------------
// Replacing deprecated size mixins since 4.3.0
// -----------------------------------------

@mixin legacy_size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include legacy_size($size, $size);
}

// -----------------------------------------
// Ellipsis for multiple lines
// -----------------------------------------
@mixin line-clamp($nbLine, $color) {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: $nbLine;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color;

  //To correct bug in Safari
  p:not(:first-child) {
    display: none;
  }
}

// -----------------------------------------
// List with separator pipe
// -----------------------------------------
@mixin list-separator-pipe($color) {
  display: flex;
  flex-wrap: wrap;

  li {
    flex-shrink: 0;

    &:not(:last-child) {
      &::after {
        content: "|";
        padding: 0 $spacer * 0.5;
        color: $color;
        speak: none;
      }
    }
  }
}

// -----------------------------------------
// List with separator border
// -----------------------------------------
@mixin list-separator-border($color) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  li {
    flex-shrink: 0;
    border-right: 1px solid $color;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1.2;

    &:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

// -----------------------------------------
// Overflow with gradient
// -----------------------------------------
@mixin overflow-gradient($height) {
  display: block;
  height: $height;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin: 0 0 0.25rem 0;
  border: 0px;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    height: toRem(5px);
    width: 100%;
    position: sticky;
    z-index: 2;
  }

  &::before {
    top: 0;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.03) 50%,
      transparent 100%
    );
  }

  &::after {
    bottom: -1px;
    background-image: linear-gradient(
      transparent 0%,
      rgba(0, 0, 0, 0.03) 50%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }
}
